import React from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Shop from "./components/shop/Shop";
import CardDetailsPage from "./components/shop/Card/CardDetailsPage";
import Login from "./components/authentication/Login";
import SignUp from "./components/authentication/SignUp";
import DashboardLayout from "./components/dashboard2/DashboardLayout";
import Home from "./components/dashboard2/Home";
import TrackGiftCard from "./components/dashboard2/TrackGiftCard";
import RecentActivity from "./components/dashboard2/RecentActivity";
import EGiftWallet from "./components/dashboard2/EGiftWallet";
import Groups from "./components/dashboard2/Groups";
import Settings from "./components/dashboard2/Settings";
import ResetPassowrd from "./components/authentication/ResetPassword";
import Invoice from "./components/dashboard2/Invoice";
import TransactionHistoryInvoice from "./components/dashboard2/TransactionHistoryInvoice";
import OrderPreview from "./components/shop/Card/OrderPreview";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Da-Secret"] = "pCweb";
if (localStorage.getItem("uuid")) {
  axios.defaults.headers.common["X-Guest-Token"] = localStorage.getItem("uuid");
} else {
  localStorage.setItem("uuid", uuidv4());
  axios.defaults.headers.common["X-Guest-Token"] = localStorage.getItem("uuid");
}

// if (process.env.NODE_ENV === "development") {
//axios.defaults.baseURL = 'https://api.primeegiftcard.com';
// } else if (process.env.NODE_ENV === "production") {
   axios.defaults.baseURL = 'https://api.20pesewas.com';
// }
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

function App() {
  return (
    <Router>
      {/* <Nav /> */}
      <Routes>
        {/* <Route path="/" element={<Home/>}/> */}
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/resetpassword" element={<ResetPassowrd />} />
        {/* <Route path="/dashboard/shop:id" element={<CardDetailsPage />} /> */}
        {/* <Route path="/dashboard" element={<Dashboard/>}/> */}
        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route path="/dashboard/" element={<Home />} />
          <Route path="/dashboard/shop" element={<Shop />} />
          <Route path="/dashboard/shop/:id" element={<CardDetailsPage />} />
          <Route path="/dashboard/track-giftCard" element={<TrackGiftCard />} />
          <Route path="/dashboard/e-gift-wallet" element={<EGiftWallet />} />
          <Route path="/dashboard/groups" element={<Groups />} />
          <Route
            path="/dashboard/recent-activity"
            element={<RecentActivity />}
          />
          <Route
            path="/dashboard/cart"
            element={<OrderPreview />}
          />
          <Route
            path="/dashboard/recent-activity/:id/invoice"
            element={<TransactionHistoryInvoice />}
          />
          <Route path="/dashboard/settings" element={<Settings />} />
          <Route path="/dashboard/invoice" element={<Invoice />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
