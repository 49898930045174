import { RotatingLines } from "react-loader-spinner";
import React from "react";

const LoadingButton = () => {
  return (
    <button
      disabled
      type="button"
      className="btn btn-secondary form-control btn-color text-nowrap"
    >
      <RotatingLines
        strokeColor="grey"
        strokeWidth="5"
        animationDuration="0.75"
        width="20"
        visible={true}
      />{" "}
      &nbsp; Please wait...
    </button>
  );
};

export default LoadingButton;
