import React from "react"

const FileUploadInstructions =()=>{
    return(
        <>
            <hr />
            <div className="subtitle2">File Upload Instructions</div>
            <hr />
            <ul>
              <li>The file must be a CSV/Excel file</li>
              <li class="sub-text light-text text-left">
                The file should contain these headers
                <div className="d-flex flex-row">
                  <ul>
                    <li>FirstName</li>
                    <li>Surname</li>
                    <li>OtherName</li>
                  </ul>
                  <ul>
                    <li>Dob (eg. 1998/01/23) </li>
                    <li>Gender[male/female]</li>
                    <li>Telephone</li>
                  </ul>
                  <ul>
                    <li>Email</li>
                    <li>Position</li>
                    <li>EmployeeId</li>
                  </ul>
                </div>
              </li>
              <li class="sub-text light-text text-left mt-2">
                Make sure there is no empty row in the file
              </li>
              <li class="sub-text light-text text-left mt-2">
                Tick the checkboxes if file contains Employee ID or DOB
              </li>
            </ul>
        </>
    )
}

export default FileUploadInstructions