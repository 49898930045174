import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import LoadingPage from "../common/LoadingPage";
import ViewEmployeeTableRow from "./ViewEmployeeTableRow";

const ViewEmployee = ({ reload, setReload, selectedGroup }) => {
  const [employees, setEmployees] = useState([]);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [isloaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
    axios
      .get(`/prime.sika/v1/plus/groups/info?group_id=${selectedGroup}`)
      .then((res) => {
        if (res.data.status === 200 && res.data.success === true) {
          setEmployees(res?.data?.data?.group?.employees);
          setLoaded(true);
        }
      })
      .catch((err) => {
        console.log(err.response);
        // Swal.fire("Failed", "Error Loading Data", "error");
        setLoaded(true);
      });
  }, [selectedGroup, refresh]);

  return (
    <div
      className="modal fade"
      id="view_employee_modal"
      aria-hidden="true"
      role="dialogs"
      tabIndex="-1"
      aria-labelledby="view_employee_modal"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body ">
            <form action="">
              <div className="row mx-1 mb-3">
                <div className="p-1">
                  <input
                    type="text"
                    placeholder="Search Employee"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="table-responsive assign-table-height">
                {isloaded ? (
                  <table className="datatable table table-stripped table table-hover table-center mb-0">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Telephone</th>
                        <th>Position</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employees
                        .filter((item) => {
                          if (search.toLowerCase() !== "") {
                            return item?.fullname
                              ?.toLowerCase()
                              ?.includes(search.toLowerCase());
                          } else {
                            return item;
                          }
                        })
                        .map((employee, index) => (
                          <ViewEmployeeTableRow
                            employee={employee}
                            index={index}
                            key={index}
                            reload={reload}
                            setReload={setReload}
                            selectedGroup={selectedGroup}
                            refresh={refresh}
                            setRefresh={setRefresh}
                          />
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="d-flex justify-content-center">
                    <LoadingPage />
                  </div>
                )}
                {/* <TableComponent/> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewEmployee;
