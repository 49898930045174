import React from "react";
import { Link } from "react-router-dom";
import BusinessDetailForm from "./businessinfoComponent/BusinessDetailForm";
import LogoUpload from "./businessinfoComponent/LogoUpload";
const BusinessInfo = ({location,getMapLocation}) => {

  return (
    <>
      <div
        className="tab-pane fade show active"
        id="pills-profile1"
        role="tabpanel"
        aria-labelledby="pills-profile-tab1"
      >
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-chart2 card-h10 card-h10-pad">
              <div>
                <h3>Business Information</h3>
                <hr className="hr3" />
              </div>
              <div>
                <h4>
                  We do not sell or share your details without your permission.
                  Find out more in our <Link to="">Privacy Policy.</Link>
                  <span>
                    Your{" "}
                    <b>
                      business name, location address, business logo and business email
                    </b>{" "}
                    can be updated via{" "}
                    <b className="merch-color">
                      PrimePlus Business Information Settings
                    </b>
                  </span>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mt-4">
            <LogoUpload/>
            <BusinessDetailForm location={location} getMapLocation={getMapLocation}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessInfo;
