import { useState, React } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import LoadingButton from "../common/LoadingButton";
import FileUploadInstructions from "./FileUploadInstructions";

const FileUploadForms = ({
  reloadTable,
  setReloadTable,
  reload,
  setReload,
  userInput, setUserInput, handleUserInput
}) => {
  const [file, setFile] = useState("");
  const [hasEmployeeID, setHasEmplyeeID] = useState(false);
  const [hasDob, setHasDob] = useState(false);
  const [uploading, setUploading] = useState(false);
 
  const [showInstructions, setShowInstructions] = useState(false);

  
  const handleUpload = (e) => {
    setUploading(true);
    e.preventDefault();
    let data = new FormData();
    data.append("employees_file", file);
    data.append("is_employee_id_required", hasEmployeeID);
    data.append("is_dob_required", hasDob);
    data.append("country_code_name", "GH");
    data.append("group_name", userInput.title);

    axios
      .post(
        `/prime.sika/v1/plus/employees/${localStorage.getItem(
          "organization_code"
        )}/add.csv`,
        data
      )
      .then((res) => {
        setUploading(false);
        if (res.data.success === true && res.data.status === 201) {
          let message = res?.data?.data?.message;
          setReload(!reload);
          Swal.fire({
            title: "Processing",
            text: message,
            icon: "info",
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: "Okay",
            confirmButtonColor: "#00b17d",
            denyButtonText: `No`,
          }).then(() => {
            setReloadTable(!reloadTable);
          });
        }
      })
      .catch((err) => {
        setUploading(false);
        if (
          err.response.status === 400 &&
          err.response.data.success === false
        ) {
          let err_message = err?.response?.data?.error_msg;
          Swal.fire({
            title: "Failed",
            text: err_message,
            icon: "error",
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: "Okay",
            confirmButtonColor: "#00b17d",
            denyButtonText: `No`,
          });
        }
      });
  };

  const handleInstructionChange = (e) => {
    setShowInstructions(!showInstructions);
  };

  return (
    <>
      <div className="p-3">
        <form onSubmit={handleUpload}>
          <div className="mb-3">
            <label htmlFor="email" className="footer-text">
              Group Title
            </label>
            <input
              type="text"
              className="form-control"
              name="title"
              placeholder="Enter group title here"
              value={userInput.title}
              onChange={handleUserInput}
              required
            />
          </div>
          <div className="mb-3 mt-3">
            <input
              type="file"
              className="form-control"
              required
              onChange={(e) => setFile(e.target.files[0])}
            />
          </div>
          <div className="d-flex  mt-3 check-text-size">
            <input
              type="checkbox"
              className="checkbox-design"
              required=""
              name="special_offer"
              id="special_offer"
              value={hasEmployeeID}
              onChange={() => setHasEmplyeeID(!hasEmployeeID)}
            />
            <label htmlFor="special_offer">
              <h4 className="ms-2 pt-1">
                File contains individual employee's ID
              </h4>
            </label>
          </div>
          <div className="d-flex  mt-3 check-text-size">
            <input
              type="checkbox"
              className="checkbox-design"
              required=""
              name="special_off"
              id="special_off"
              value={hasEmployeeID}
              onChange={() => setHasDob(!hasDob)}
            />
            <label htmlFor="special_off">
              <h4 className="ms-2 pt-1">
                File contains each employee's date of birth
              </h4>
            </label>
          </div>
          {!uploading ? (
            <button
              type="submit"
              className="btn btn-outline-success form-control mt-3"
              // data-bs-dismiss="modal"
              // aria-label="Close"
            >
              Save
            </button>
          ) : (
            <LoadingButton />
          )}

          <a
            href="/assets/employee_template.csv"
            className="btn btn-outline-success-black btn-outline-dark white-text-hover mt-2 form-control"
          >
            Download Sample File Template
          </a>
        </form>
      </div>
      <div className="d-flex  mt-3 check-text-size">
        <div className="ms-3">
          <input
            type="checkbox"
            className="checkbox-design"
            required=""
            name="special_off"
            id="special_off"
            value={showInstructions}
            onChange={handleInstructionChange}
          />
          <label htmlFor="special_off">
            <h4 className="ms-2">Show file upload instructions</h4>
          </label>
        </div>
      </div>
      {showInstructions && <FileUploadInstructions />}
    </>
  );
};
export default FileUploadForms;
