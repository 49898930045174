import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import LoadingButton from "../../common/LoadingButton";

const ResetVerificationForm = ({ setCurrent, userInput, errorMessage, setErrorMessage, setVerificationCode, handleValidation }) => {
    const box1 = useRef("");
    const box2 = useRef("");
    const box3 = useRef("");
    const box4 = useRef("");
    const box5 = useRef("");
    const box6 = useRef("");

    const navigate = useNavigate();
    const [processing, setProcessing] = useState(false);
    const [sendingSms, setSendingSms] = useState(false);

    useEffect(() => {
        box1.current.focus();
    }, []);

    const [code, setCode] = useState({
        one: "",
        two: "",
        three: "",
        four: "",
        five: "",
        six: ""
    });

    useEffect(() => {
        setVerificationCode(
            code.one + code.two + code.three + code.four + code.five + code.six
        );
    }, [code]);

    const handleNextFocus = (e) => {
        setErrorMessage("");
        setCode((code) => ({
            ...code,
            [e.target.id]: e.target.value.toString()
        }));

        if (e.target.id === "one" && e.target.value !== "") {
            box2.current.focus();
        }
        if (e.target.id === "two" && e.target.value !== "") {
            box3.current.focus();
        }
        if (e.target.id === "three" && e.target.value !== "") {
            box4.current.focus();
        }
        if (e.target.id === "four" && e.target.value !== "") {
            box5.current.focus();
        }
        if (e.target.id === "five" && e.target.value !== "") {
            box6.current.focus();
        }
        //code.one+code.two+code.three+code.four+code.five+code.six
    };




    const resendViaSms = (e) => {
        setSendingSms(true);
        axios
            .post("/prime.sika/v1/users/business_customer/resend-verification", {
                telephone: userInput.phone,
                user_type: "business_customer"
            })
            .then((res) => {
                console.log(res.data);
                setSendingSms(false);
            })
            .catch((err) => {
                setSendingSms(false);
                console.log(err.response);
            });
    };

    const resendViaEmail = (e) => {
        console.log(e.target);
    };

    return (
        <div className="col-lg-12">
            <div className="mb-5">
                <Link to="/login">
                    <img src="/assets/images/arrow-left.png" alt="" />
                </Link>
            </div>
            <div>
                <h3>Password Reset</h3>
            </div>
            <div>
                <h4>We've sent a 6 digit verification code to your phone</h4>
            </div>
            <div className="font-enter">
                <h4>
                    Please enter the code below to verify it's you
                </h4>
            </div>
            <div className="form-group mt-4 d-flex">
                <input
                    type="text"
                    id="one"
                    className="form-control textbox-size1"
                    min={0}
                    max={9}
                    maxLength={1}
                    ref={box1}
                    value={code.one}
                    onChange={handleNextFocus}
                />
                <input
                    type="text"
                    id="two"
                    className="form-control textbox-size1 ms-2"
                    maxLength="1"
                    ref={box2}
                    value={code.two}
                    onChange={handleNextFocus}
                />
                <input
                    type="text"
                    id="three"
                    className="form-control textbox-size1 ms-2"
                    maxLength="1"
                    ref={box3}
                    value={code.three}
                    onChange={handleNextFocus}
                />
                <input
                    type="text"
                    id="four"
                    className="form-control textbox-size1 ms-2"
                    maxLength="1"
                    ref={box4}
                    value={code.four}
                    onChange={handleNextFocus}
                />
                <input
                    type="text"
                    id="five"
                    className="form-control textbox-size1 ms-2"
                    maxLength="1"
                    ref={box5}
                    value={code.five}
                    onChange={handleNextFocus}
                />
                <input
                    type="text"
                    id="six"
                    className="form-control textbox-size1 ms-2"
                    maxLength="1"
                    ref={box6}
                    value={code.six}
                    onChange={handleNextFocus}
                />
            </div>
            <small className="text-danger">{errorMessage}</small>
            <div className="mt-4 continue-btn d-flex justify-content-between">
                {!processing ? (
                    <button
                        className="btn btn-success btn-next"
                        onClick={handleValidation}
                    >
                        Continue
                    </button>
                ) : (
                    <LoadingButton />
                )}
                <div className=" ml-2 text-left no-text-wrap">
                    {userInput.phone && (
                        <button
                            type="button"
                            className="btn btn-dark log-in-btn-size"
                            onClick={resendViaSms}
                        >
                            {!sendingSms ? <>Resend code</> : <>Processing</>}
                        </button>
                    )}

                    {/* {userInput.email && (
            <a
              href="#"
              className="text-success text-resend-color no-text-wrap"
              onClick={resendViaEmail}
            >
              Resend via Email
            </a>
          )} */}
                </div>
            </div>
        </div>
    );
};

export default ResetVerificationForm;
