import React, { useEffect, useState } from "react";
import axios from "axios";
import AssignEmployeeTableRow from "./AssignEmployeeTableRow";
import Swal from "sweetalert2";

const AssignEmployeeToGroup = ({ reload, setReload, selectedGroup }) => {
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("");
  const [employees, setEmployees] = useState([]);
  const [positions, setPositions] = useState([]);
  const [refresh,toggleRefresh]=useState(false);
  const [isLoading,setLoading]=useState(false);

  useEffect(() => {
    setLoading(true)
    axios
      .get(
        `/prime.sika/v1/plus/employees/groups/list?group_id=${selectedGroup}&belongs_to_group=false`
      )
      .then((res) => {
        if (res.data.status === 200 && res.data.success === true) {
          setLoading(false)
          setEmployees(res.data.data.employees);
          setReload(!reload);
        }
      })
      .catch((err) => {
        setLoading(false)
        // Swal.fire({
        //   title:'Error',
        //   icon:'error',
        //   text:'Error Loading Records',
        //   confirmButtonColor:'#198754'
        // })
        // console.log(err.response);
      });
  }, [selectedGroup,refresh]);

  useEffect(() => {
    axios.get("/prime.sika/v1/plus/employees/categories").then((res) => {
      if (res.data.success === true && res.data.status === 200) {
        setPositions(res.data.data.categories);
      }
    });
  },[]);

  return (
    <div
      className="modal fade"
      id="assign_employee_modal"
      aria-hidden="true"
      role="dialogs"
      tabIndex="-1"
      aria-labelledby="assign_employee_modal"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-body ">
            <div className="col-lg-12">
              <div className="card card-table">
                <div className="card-header">
                  <div className="d-flex flex-row justify-content-between">
                    <h3 className="my-auto contact-text">
                      Assign Employees to Group
                    </h3>
                    {/* <h4 className="shop-text-desc2 float-start">
                      <button className="btn btn-success">Assign All</button>
                    </h4> */}
                  </div>
                </div>
                <div className="card-table">
                  <div className="card-body booking_card">
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <div className="form-group">
                          <select
                            className="form-control w-100"
                            onChange={(e) => {
                              setSearch("");
                              setCategory(e.target.value);
                            }}
                          >
                            <option value="">
                              --- Filter By Positions ---
                            </option>
                            {positions.map((position, index) => (
                              <option
                                key={index}
                                value={position}
                                className="text-capitalize"
                              >
                                <div className="text-capitalize">{position.toUpperCase()}</div>
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4"></div>
                      <div className="col-md-4">
                        <input
                          type="text"
                          placeholder="Search Employee"
                          onChange={(e) => {
                            setCategory("");
                            setSearch(e.target.value);
                          }}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="datatable table table-stripped table table-hover table-center mb-0">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Telephone</th>
                            <th>Position</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            isLoading?
                            <tr>
                              <td colSpan={4} className='text-center'><h3>Loading... Please Wait...</h3></td>
                            </tr>
                            :
                          employees
                            .filter((item) => {
                              if (search.toLowerCase() !== "") {
                                return (
                                  item?.fullname
                                    ?.toLowerCase()
                                    ?.includes(search.toLowerCase()) ||
                                  item?.telephone?.includes(search)
                                );
                              } else if (category !== "") {
                                return item?.position
                                  ?.toLowerCase()
                                  .includes(category);
                              } else {
                                return item;
                              }
                            })
                            .map((employee, index) => (
                              <AssignEmployeeTableRow
                                key={index}
                                employee={employee}
                                index={index}
                                selectedGroup={selectedGroup}
                                reload={reload}
                                refresh={refresh}
                                toggleRefresh={toggleRefresh}
                                setReload={setReload}
                              />
                            ))
                          }
                          {employees?.length===0 && <tr>
                        <td colSpan={4} className='text-center'><h3>No Employee Available</h3></td>
                      </tr>}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AssignEmployeeToGroup;
