import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const AssignedCards = ({ reload, toggleReload }) => {
  const navigate = useNavigate();
  const [cart, setCart] = useState([]);

  useEffect(() => {
    axios
      .get("/prime.sika/v1/plus/accounts/current-card-link-cart")
      .then((res) => {
        if (res.data.success === true && res.data.status === 200) {
          setCart(res?.data?.data?.cart);
        }
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 &&
          err?.response?.data?.success === false &&
          err?.response?.data?.error_msg === "Unauthorized"
        ) {
          localStorage.removeItem("token");
          navigate("/login");
        }
      });
  }, [navigate, reload]);

  const sendSingleCard = (cardId) => {
    Swal.fire({
      title: "Confirmation",
      icon: "question",
      text: "Do want to proceed on sending this card? ,please note that, this action can't be reversed.",
      showDenyButton: true,
      denyButtonText: "No",
      showConfirmButton: true,
      confirmButtonText: "Yes",
    }).then((_) => {
      axios
        .post(
          "/prime.sika/v1/plus/accounts/business_customer/cart-bulk-schedule",
          {
            cart_id: Number(cart?.id),
            item_ids: [cardId],
          }
        )
        .then((res) => {
          if (res.data.success === true && res.data.status === 201) {
            toggleReload(!reload);
            Swal.fire({
              title: "Success",
              text: "Card has been  scheduled for sending, Kindly check \"Track Gift Card\" section to check the card delivery status.",
              icon: "success",
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: "Okay",
              confirmButtonColor: "#00b17d",
              denyButtonText: `No`,
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    });
  };

  const handleUnassign = (account_id) => {
    Swal.fire({
      title: "Confirmation",
      text: "Do you want to proceed and unassign this gift card?",
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
    }).then((results) => {
      if (results.isConfirmed) {
        axios
          .delete(
            "/prime.sika/v1/plus/accounts/business_customer/remove-cart-items",
            {
              data: {
                cart_id: cart?.id,
                item_ids: [Number(account_id)],
              },
            }
          )
          .then((res) => {
            console.log(res.data);
            if (res.data.success === true && res.data.status === 200) {
              toggleReload(!reload);
              Swal.fire({
                title: "Success",
                icon: "success",
                text: "Card(s) has been unassigned successfully",
                confirmButtonText: "Okay",
                showCancelButton: false,
              });
            }
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    });
  };

  return (
    <>
      <div className="col-lg-12 mt-5">
        <div className="table-responsive tb-h">
          <table className="datatable table table-stripped table table-hover table-center mb-0">
            <thead>
              <tr>
                <th>e-Gift Cards</th>
                <th>Employee</th>
                <th></th>
                <th>Telephone</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {cart?.items
                ?.filter((item) => {
                  return item?.status === "UNASSIGNED_ITEM";
                })
                .map((ele, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className="d-flex">
                          <div className="table-img-size">
                            <img src={ele?.card?.card?.image} alt="" />
                          </div>
                          <div className="d-block ms-3 mt-3">
                            <div className="table-amt-font">
                              <h4>
                                Amount{" "}
                                <span>¢{ele?.card?.current_balance}</span>
                              </h4>
                            </div>
                            <div className="table-name-font">
                              <h4>{ele?.card?.card?.title}</h4>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>{ele?.employee?.fullname}</td>
                      <td></td>
                      <td>{ele?.employee?.telephone}</td>
                      <td>
                        <div className="d-flex">
                          <div className="p-2">
                            <button
                              type="button"
                              className="btn btn-outline-danger marg-left1"
                              id={ele?.id}
                              onClick={(e) => handleUnassign(e.target.id)}
                            >
                              Unassign
                            </button>
                          </div>
                          <div className="p-2">
                            <button
                              type="button"
                              className="btn btn-success marg-left1"
                              // card_id={ele?.card?.account_id}
                              // employee_id={ele?.employee?.id}
                              onClick={() =>
                                sendSingleCard(
                                  ele?.employee?.id,
                                  ele?.card?.account_id
                                )
                              }
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {/* <TableComponent/> */}
        </div>
      </div>
    </>
  );
};

export default AssignedCards;
