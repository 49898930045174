import { Link, useNavigate } from "react-router-dom";
import { useState,React } from "react";
import VerificationScreen from "./signupComponents/VerificationScreen";
import ContactDetails from "./signupComponents/ContactDetails";
import Credentials from "./signupComponents/Credentials";
import PersonalDetails from "./signupComponents/PersonalDetails";
import ThankYou from "./signupComponents/ThankYou";
import axios from "axios";
import Swal from "sweetalert2";

const SignUp = () => {
  const [nextScreen, setNextScreen] = useState(0);
  const navigate = useNavigate();

  const [userInput, setUserInput] = useState({
    business_name: "",
    business_location: "",
    first_name: "",
    surname: "",
    password: "",
    email: "",
    phone: "",
    security_pin: ""
  });

  const getCountry = (item) => {
    setUserInput((userInput) => ({
      ...userInput,
      country_name: item.name,
      code_number: item.code_number,
      code_name: item.code_name
    }));
  };

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value
    }));
  };

  const changeScreen = (e) => {
    e.preventDefault();
    setNextScreen((prev) => prev + 1);
  };

  const [location, setlocation] = useState({
    lng: "",
    lat: "",
    address: ""
  });

  const getMapLocation = (lngParam, latParam, address) => {
    setlocation((location) => ({
      ...location,
      lng: lngParam,
      lat: latParam,
      address: address
    }));
  };

  const [registrationFailed, setRegistrationFailed] = useState(false);
  const handleSubmit = () => {
    const data = {
      password: userInput.password,
      verification_by: "email",
      telephone: userInput.phone,
      email: userInput.email,
      first_name: userInput.first_name,
      surname: userInput.surname,
      country_code_name: "GH",
      // name: userInput.name,
      // pin: userInput.pin,
      organization: {
        name: userInput.business_name,
        telephone: userInput.phone,
        email: userInput.email,
        telephones: [userInput.phone],
        location_cordinates: {
          latitude: location.lat,
          longitude: location.lng
        }
      }
    };
    console.log(data);
    axios
      .post("prime.sika/v1/plus/customers/signup", data)
      .then((res) => {
        console.log(res.data);
        if (res.data.success === "true" && res.data.status === 200) {
          navigate("/login");
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.status === 400) {
          alert(err.response.data.error_msg);
          setNextScreen(0);
          setRegistrationFailed(true);
        }
      });
  };

  if (nextScreen === 0) {
    return (
      <div className="d-flex justify-content-between">
        <div className="col-md-5 left-div">
          <div className="left-div-content">
            <h1 className="text-white">
              Take advantage <span>of our yearly subscription </span>and save.
            </h1>
          </div>
          <div className="left-content">
            <h2 className="text-white">
              Looking to add style and <span>class to your gifting?</span>
            </h2>
          </div>
          <div className="left-div-cont-last">
            <h4 className="text-white">
              Subscribe to any of our elite services and{" "}
              <span>have your e-gift card printed, professionally</span>
              packaged and delivered
            </h4>
            <ul className="text-white">
              <li>Standard</li>
              <li>Executive</li>
              <li>Prestige</li>
            </ul>
          </div>
          <div className="mt-5">
            <a href="/signup" className="btn btn-green ">
              Learn more
            </a>
          </div>
          <div className="back-img">
            <img src="assets/images/gift-img.png" alt="signup" width="60%" />
          </div>
        </div>

        <div className="col-md-6 pr-0 right-div">
          <div className="card card-size1 mt-5">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex float-end log-size">
                    <h4>
                      Already registered?
                      <Link
                        to="/login"
                        className="btn btn-outline-success btn-outline-dark"
                      >
                        Log In
                      </Link>
                    </h4>
                  </div>
                </div>
                <PersonalDetails
                  handleUserInput={handleUserInput}
                  userInput={userInput}
                  changeScreen={changeScreen}
                  getMapLocation={getMapLocation}
                  location={location}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (nextScreen === 1) {
    return (
      <div className="row justify-content-between">
        <div className="col-lg-5 col-12 left-div">
          <div className="left-div-padding">
            <div className="left-div-content1">
              <h1 className="text-white">
                E-gift card <span>Bulk purchase</span>
              </h1>
            </div>
            <div className="left-content1">
              <h2 className="text-white">Made simple!</h2>
            </div>
            <div className="left-div-cont-last1">
              <ul className="text-white">
                <li>Select e-gift cards of your choice,</li>
                <li>Upload Excel sheet of recepients name and contacts</li>
                <li>Add message and or repeat for all</li>
              </ul>
            </div>
            <div className="mt-5">
              <a href="/signup" className="btn btn-green learn-more-btn1">
                Learn more
              </a>
            </div>
          </div>
          <div className="back-img1">
            <img src="assets/images/image-28.png" alt="" width="65%" />
          </div>
        </div>
        <div className="col-lg-6 col-12 pr-0 right-div">
          <div className="card card-size1 mt-5">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex justify-content-between">
                    <button onClick={() => setNextScreen(0)}>
                      <img src="/assets/images/arrow-left.png" alt="" />
                    </button>
                    <div className="d-flex float-end log-size">
                      <h4>
                        Already registered?
                        <Link to="/login" className="btn btn-outline-dark">
                          Log In
                        </Link>
                      </h4>
                    </div>
                  </div>
                </div>
                <ContactDetails
                  handleUserInput={handleUserInput}
                  userInput={userInput}
                  changeScreen={changeScreen}
                  setUserInput={setUserInput}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (nextScreen === 2) {
    return (
      <div className="row justify-content-between">
        <div className="col-lg-5 col-12 left-div">
          <div className="left-div-padding">
            <div className="left-div-content1">
              <h1 className="text-white">
                Take advantage <span>of special deals</span>
              </h1>
            </div>
            <div className="left-content1">
              <h2 className="text-white">Be the first to know</h2>
            </div>
            <div className="left-div-cont-last1">
              <h3>
                Of special deals on the brands you love.{" "}
                <span>Subscribe to our e-newsletter</span>
              </h3>
            </div>
            <div className="mt-5">
              <a href="/signup" className="btn btn-green learn-more-btn1">
                Learn more
              </a>
            </div>
          </div>
          <div className="back-img1">
            <img src="assets/images/image-30.png" alt="" width="65%" />
          </div>
        </div>
        <Credentials
          handleUserInput={handleUserInput}
          userInput={userInput}
          changeScreen={changeScreen}
          getCountry={getCountry}
          nextScreen={nextScreen}
          setNextScreen={setNextScreen}
          latitude={location.lat}
          longitude={location.lng}
        />
      </div>
    );
  } else if (nextScreen === 3) {
    return (
      <div className="d-flex justify-content-between">
        <div className="col-lg-5 col-12 left-div">
          <div className="left-div-padding">
            <div className="left-div-content1">
              <h1 className="text-white">
                Give your customers <span>the gift of choice.</span>
              </h1>
            </div>
            <div className="left-content1">
              <h2 className="text-white">Join our merchants</h2>
            </div>
            <div className="left-div-cont-last1">
              <h3>
                And have your e-gift card sold on Prime for{" "}
                <span>customers to purchase and share in your brand</span>
              </h3>
            </div>
            <div className="mt-5">
              <a href="/signup" className="btn btn-green learn-more-btn1">
                Learn more
              </a>
            </div>
          </div>
          <div className="back-img2">
            <img src="assets/images/bg6.png" alt="" width="70%" />
          </div>
        </div>
        <VerificationScreen
          changeScreen={changeScreen}
          userInput={userInput}
          setUserInput={setUserInput}
          handleSubmit={handleSubmit}
          registrationFailed={registrationFailed}
          nextScreen={nextScreen}
          setNextScreen={setNextScreen}
        />
      </div>
    );
  }
  // else if (nextScreen === 0) {
  //   return <ThankYou />;
  // }
};

export default SignUp;
