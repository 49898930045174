import React, { useState, useEffect } from "react";
import axios from "axios";
import LoadingButton from "./../../common/LoadingButton";
import { useNavigate } from "react-router-dom";

const EmployeeListScreen = ({
  setScreen,
  setSearch,
  setCategory,
  employees,
  search,
  category,
  userInput,
  setUserInput,
  handleUserInput,
  // assignGiftCard,
}) => {
  const navigate = useNavigate();
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    axios
      .get("/prime.sika/v1/plus/employees/categories")
      .then((res) => {
        if (res.data.success === true && res.data.status === 200) {
          setPositions(res.data.data.categories);
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (
          err.response.data.status === 401 &&
          err.response.data.success === false &&
          err.response.data.error_msg === "Unauthorized"
        ) {
          localStorage.removeItem("token");
          navigate("/login");
        }
      });
  }, []);

  return (
    <>
      <h3 className="text-center click-font">
        Click on Assign to assign card to employee
      </h3>
      <hr />
      <div className="d-flex justify-content-between mb-3">
        <div className="col-md-4">
          <div className="form-group">
            <select
              className="form-control w-100"
              onChange={(e) => {
                setSearch("");
                setCategory(e.target.value);
              }}
            >
              <option value="">--- Filter By Positions ---</option>
              {positions.map((position, index) => (
                <option
                  key={index}
                  value={position}
                  className="text-capitalize"
                >
                  <div className="text-capitalize">
                    {position.toUpperCase()}
                  </div>
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="p-1">
          <input
            type="text"
            placeholder="Search Employee"
            onChange={(e) => {
              setCategory("");
              setSearch(e.target.value);
            }}
            className="form-control"
          />
        </div>
      </div>
      <div className="table-responsive assign-table-height1">
        <table className="datatable table table-stripped table table-hover table-center mb-0">
          <thead>
            <tr>
              <th>Employee's Name</th>
              <th>Telephone</th>
              <th>Position</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {employees
              .filter((item) => {
                if (search.toLowerCase() !== "") {
                  return item?.fullname?.toLowerCase()?.includes(search);
                } else if (category !== "") {
                  return item?.position?.toLowerCase().includes(category);
                } else {
                  return item;
                }
              })
              .map((employee, index) => {
                return (
                  <tr key={index}>
                    <td>{employee?.fullname}</td>
                    <td>{employee?.telephone}</td>
                    <td className="text-capitalize">{employee?.position}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => {
                          // assignGiftCard(employee?.id)
                          setUserInput((userInput) => ({
                            ...userInput,
                            employee_id: employee?.id,
                          }));
                          setScreen("schedule");
                        }}
                        // data-bs-dismiss="modal"
                        // aria-label="Close"
                      >
                        Assign
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {/* <TableComponent/> */}
      </div>
    </>
  );
};

export default EmployeeListScreen;
