import { useState, useEffect,React } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const ContactDetails = ({
  userInput,
  handleUserInput,
  changeScreen,
  setUserInput
}) => {
  const [errors, setError] = useState({
    first_name: "",
    surname: "",
    phone: "",
    password_confirmation: ""
  });

  const handleOnChange = (e) => {
    setError((error) => ({
      ...error,
      [e.target.name]: ""
    }));
    handleUserInput(e);
  };

  const verification = (e) => {
    e.preventDefault();
    if (!userInput.first_name || userInput.first_name === "") {
      setError((error) => ({
        ...error,
        first_name: "Please enter your first name"
      }));
    } else if (!userInput.surname || userInput.surname === "") {
      setError((error) => ({
        ...error,
        surname: "Please enter your surname"
      }));
    } else if (!userInput.phone || userInput.phone === "") {
      setError((error) => ({
        ...error,
        phone: "Please enter your business phone number"
      }));
    } else {
      changeScreen(e);
    }
  };

  const [phone, setPhone] = useState();

  useEffect(() => {
    setUserInput((userInput) => ({
      ...userInput,
      phone: phone
    }));
  }, [phone]);

  return (
    <>
      <div className="col-lg-12">
        <div className="mt-3">
          <h3>Sign up to Prime+</h3>
        </div>
        <div>
          <h4>Enter your details below</h4>
        </div>
        <div className="d-flex line-grp">
          <div className="line active"></div>
          <div className="line active"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <form onSubmit={verification}>
          <div className="font-enter">
            <h4>Enter account admin info & business contact number</h4>
          </div>
          <div className="form-group mt-4">
            <input
              type="text"
              className="form-control textbx-size1"
              placeholder="Enter your first name here"
              value={userInput.first_name}
              name="first_name"
              onChange={handleOnChange}
              required
            />
            <small className="text-danger">{errors.first_name}</small>
          </div>
          <div className="form-group mt-4">
            <input
              type="text"
              className="form-control textbx-size1"
              placeholder="Enter your surname name here"
              value={userInput.surname}
              name="surname"
              onChange={handleOnChange}
              required
            />
            <small className="text-danger">{errors.surname}</small>
          </div>
          <div className="form-group mt-4">
            <PhoneInput
              // type="tel"
              className="form-control textbx-size1"
              placeholder="Enter your phone number here (Optional)"
              defaultCountry="GH"
              value={phone}
              name="phone"
              onChange={setPhone}
              // required
            />
          </div>
          <div className="mt-4 continue-btn">
            <button type="submit" className="btn btn-dark">
              Continue
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ContactDetails;
