import { Link } from "react-router-dom";
import LoginForm from "./partials/LoginForm";
import React from "react";

const Login = () => {
  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-12 ">
          <div className="mt-3">
            <img src="/assets/images/bg5.png" alt="" width="100%" />
          </div>
          <div className="left-div-content-3">
            <h3>
            Give your staff the gift of choice
            </h3>
          </div>
        </div>
        <div className="col-lg-6 col-12 pr-0">
          <div className="card card-size mt-3">
            <div className="card-body">
              <div>
                <h3 className="text-left">Log in to Prime+</h3>
              </div>
              <div>
                <h4>Enter your details below</h4>
              </div>
              <LoginForm />
              <div className="row content-items-last">
                <div className="col-12">
                  <h4>
                    Don't have an account?
                    <Link to="/signup" className="text-success ml-1 ">
                      Sign Up
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="left-div-cont">
            <h5>
              PRIME - We are on a mission to make gifting fun, personalized and
              simple with e-gift card.
            </h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
