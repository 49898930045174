import axios from "axios";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import NewPasswordForm from "./resetPasswordComponents/NewPasswordForm";
import ResetVerificationForm from "./resetPasswordComponents/ResetVerificationForm";

const ResetPassowrd = () => {

    const [phone, setPhone] = useState();
    const [processing, setProcessing] = useState(false);
    const [sendingSms, setSendingSms] = useState(false);
    const [userInput, setUserInput] = useState({
        phone: ""
    });

    const [current, setCurrent] = useState(1)
    const [token, setToken] = useState(null)

    useEffect(() => {
        setUserInput((userInput) => ({
            ...userInput,
            phone: phone
        }));
    }, [phone]);
    const getCountry = (item) => {
        setUserInput((userInput) => ({
            ...userInput,
            country_name: item.name,
            code_number: item.code_number,
            code_name: item.code_name
        }));
    };

    const handleUserInput = (e) => {
        setUserInput((userInput) => ({
            ...userInput,
            [e.target.name]: e.target.value
        }));
    };

    const [errors, setError] = useState({
        first_name: "",
        surname: "",
        phone: "",
        password_confirmation: ""
    });

    const handleOnChange = (e) => {
        setError((error) => ({
            ...error,
            [e.target.name]: ""
        }));
        handleUserInput(e);
    };

    const resendViaSms = (e) => {
        setSendingSms(true);
        e.preventDefault();

        if (!userInput.phone || userInput.phone === "") {
            setError((error) => ({
                ...error,
                phone: "Please enter your phone number"
            }));

        } else {
            axios
                .post("/prime.sika/v1/customers/user-otp", {
                    telephone: userInput.phone,
                    user_type: "business_customer"
                })
                .then((res) => {
                    setCurrent(2)

                })
                .catch((err) => {
                    console.log(err.response);
                    Swal.fire({
                        title: "Error",
                        text: err.response.data.error_msg,
                        icon: "error",
                        button: "OK",
                        confirmButtonColor: "#00b17d",

                    });
                });
        }
    };

    const [errorMessage, setErrorMessage] = useState("");
    const [verificationCode, setVerificationCode] = useState("");


    const handleValidation = (e) => {
        setProcessing(true);
        if (verificationCode.length !== 6) {
            setProcessing(false);
            setErrorMessage("Invalid Verification Code Entered");
        } else {
            axios
                .post(
                    `/prime.sika/v1/customers/verify-user-otp?telephone=${userInput.phone}&otp=${verificationCode}&user_type=business_customer`,
                )
                .then((res) => {
                    localStorage.setItem("new_code", res.data.data.new_code)
                    setCurrent(3)
                    setProcessing(false);
                })
                .catch((err) => {
                    setProcessing(false);
                    console.log(err.response.data);
                    setErrorMessage("Invalid Verification Code Entered");
                });
            // changeScreen(e);
        }
    };

    return (
        <>
            <div className="row justify-content-center align-items-center">
                <div className="col-lg-6 col-12 ">
                    <div className="mt-3">
                        <img src="/assets/images/bg5.png" alt="" width="100%" />
                    </div>
                    <div className="left-div-content-3">
                        <h3>
                            Give your staff the gift of choice
                        </h3>
                    </div>
                </div>
                <div className="col-lg-6 col-12 pr-0">
                    <div className="card card-size">
                        <div className="card-body">
                            {current === 1 && (
                                <>
                                    <div className="mb-2">
                                        <Link to="/login">
                                            <img src="/assets/images/arrow-left.png" alt="" />
                                        </Link>
                                    </div>
                                    <div>
                                        <h3 className="text-left">Forgot Password?</h3>
                                    </div>
                                    <div>
                                        <h4>Recover your Password by entering your phone number</h4>
                                    </div>
                                    <form>
                                        <div className="form-group mt-5">
                                            <PhoneInput
                                                // type="tel"
                                                className="form-control textbx-size"
                                                placeholder="Enter your phone number here"
                                                defaultCountry="GH"
                                                value={phone}
                                                name="phone"
                                                onChange={setPhone}
                                            // required
                                            />
                                            <div className="img-backg">
                                                <img
                                                    src="assets/images/call.png"
                                                    alt=""
                                                    className="img-fluid img-pos"
                                                />
                                            </div>
                                            <div className="text-danger">{errors.phone}</div>
                                        </div>
                                        <button
                                            type="button"
                                            className="btn btn-dark log-in-btn-size mt-4"
                                            onClick={resendViaSms}
                                        >
                                            Continue
                                        </button>
                                    </form>
                                </>
                            )
                            }
                            {current === 2 && (
                                <ResetVerificationForm
                                    current={current}
                                    userInput={userInput}
                                    setCurrent={setCurrent}
                                    setToken={setToken}
                                    token={token}
                                    errorMessage={errorMessage}
                                    setErrorMessage={setErrorMessage}
                                    setVerificationCode={setVerificationCode}
                                    handleValidation={handleValidation}
                                />

                            )
                            }
                            {current === 3 && (
                                <NewPasswordForm
                                    current={current}
                                    userInput={userInput}
                                    handleUserInput={handleUserInput}
                                    setCurrent={setCurrent}
                                    token={token}
                                    setToken={setToken}
                                />

                            )
                            }
                        </div>
                    </div>
                    <div className="left-div-cont">
                        <h5>
                            PRIME - We are on a mission to make gifting fun, personalized and
                            simple with e-gift card.
                        </h5>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ResetPassowrd 