import { useParams } from "react-router-dom";
import Footer from "../../partials/Footer";
import { useCallback, useEffect, useState,React } from "react";
import CardDetails from "./CardDetails";
import axios from "axios";
import OrderPreview from "./OrderPreview";
import ReceipientUpload from "./ReceipientUpload";
import OrderPreviewWithEmployees from "./OrderPreviewWithEmployees";

const CardDetailsPage = () => {
  const { id } = useParams();
  const [total, setTotal] = useState(0);
  const [isAmountSelected, setAmountSelected] = useState(false);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    axios
      .get(`/prime.sika/v1/published-cards/list`, {
        params: { merchant_ids: [id] }
      })
      .then((res) => {
        if (res.data.status === 200 && res.data.success === true) {
          setCards(res.data.data.cards);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [id]);

  const [cart, setCart] = useState([]);

  const [userInput, setUserInput] = useState({
    amount: [],
    quantity: 0,
    for: "single",
    phone: "",
    message: "",
    scheduled_date:"",
    occasion_type:"",
  });
  const [amountArr, setAmountArr] = useState([]);

  const handleUserInput = (e) => {
    localStorage.setItem(e.target.name,e.target.value);
    setUserInput(() => ({
      ...userInput,
      [e.target.name]: e.target.value
    }));
  };

  const appendAmount = (e, id) => {
    var amounts = amountArr;
    amounts.push(Number(e));
    checkIfAmountIsSelected(amounts);
    var data = {
      quantity: "1",
      card_id: id
    };
    setCart((cart) => [...cart, data]);
  };

  const popAmount = (e, id) => {
    var amounts = amountArr;
    amounts = amounts.filter((value) => value !== Number(e));
    checkIfAmountIsSelected(amounts);
    var data = cart.filter((el) => el.card_id !== id);
    setCart(data);
  };

  const checkIfAmountIsSelected = useCallback(
    (amounts) => {
      if (amounts !== null) {
        setAmountArr(amounts);
      }
      if (amountArr.length !== 0) {
        setAmountSelected(true);
      } else {
        setAmountSelected(false);
      }

      if (userInput.for === "single") {
        setTotal(amountArr.reduce((a, b) => a + b, 0));
      }
    },
    [setAmountArr, amountArr, userInput.for]
  );

  useEffect(() => {
    checkIfAmountIsSelected(null);
  }, [amountArr, checkIfAmountIsSelected]);

  const handleSelected = (e) => {};
  const [useDob, setUseDob] = useState(false);
  // New Features Scripts
  // const [purchaseType, setPurchaseType] = useState("for_employees");
  const [purchaseType, setPurchaseType] = useState("later_use");
  const clearSelectedOptions = () => {
    // setPurchaseType("for_employees");
    setPurchaseType("later_use");
    setAmountArr([]);
  };

  const [screen, setScreen] = useState("card-details");
  if (screen === "card-details") {
    return (
      <>
        <CardDetails
          cards={cards}
          handleSelected={handleSelected}
          userInput={userInput}
          handleUserInput={handleUserInput}
          setScreen={setScreen}
          appendAmount={appendAmount}
          popAmount={popAmount}
          amountArr={amountArr}
          isAmountSelected={isAmountSelected}
          total={total}
          cart={cart}
          setPurchaseType={setPurchaseType}
          purchaseType={purchaseType}
          useDob={useDob}
          setUseDob={setUseDob}
        />

        {/* <Footer /> */}
      </>
    );
  } else if (screen === "receipients") {
    return (
      <>
        <ReceipientUpload
          useDob={useDob}
          handleSelected={handleSelected}
          userInput={userInput}
          handleUserInput={handleUserInput}
          total={total}
          setScreen={setScreen}
          cart={cart}
          clearSelectedOptions={clearSelectedOptions}
        />

        <Footer />
      </>
    );
  } else if (screen === "preview") {
    return (
      <>
        <OrderPreview
          card={cards[0]}
          handleSelected={handleSelected}
          userInput={userInput}
          handleUserInput={handleUserInput}
          setScreen={setScreen}
          clearSelectedOptions={clearSelectedOptions}
          screen={screen}
        />

        <Footer />
      </>
    );
  } else if (screen === "orderPreviewWithEmployees") {
    return (
      <OrderPreviewWithEmployees setScreen={setScreen} userInput={userInput} screen={screen}/>
    );
  }
  //  else if (screen === "preview2") {
  //   return (
  //     <>
  //       <OrderPreview2
  //         location={location}
  //         handleSelected={handleSelected}
  //         userInput={userInput}
  //         handleUserInput={handleUserInput}
  //       />

  //       <Footer />
  //     </>
  //   );
  // }
};

export default CardDetailsPage;
