import BusinessInfo from "./settingsComponents/BusinessInfo";
import PersonalInfo from "./settingsComponents/PersonalInfo";
import GeneralSettingsTab from "./settingsComponents/partials/GeneralSettingsTab";
import ProfileSideTab from "./settingsComponents/partials/ProfileSideTab";
import BusinessProfileTab from "./settingsComponents/partials/BusinessProfileTab";
import { useState,React } from "react";

const Settings = () => {
    const [location, setlocation] = useState({
        lng: "",
        lat: "",
        address: ""
      });
    
      const getMapLocation = (lngParam, latParam, address) => {
        setlocation((location) => ({
          ...location,
          lng: lngParam,
          lat: latParam,
          address: address
        }));
      };

    return (
        <>
            <div className="content container-fluid">
                <GeneralSettingsTab />
                <div className="row">
                    <div className="col-lg-11">
                        <div className="tab-content" id="pills-tabContent">
                            <div
                                className="card card-back tab-pane fade show active"
                                id="pills-profile"
                                role="tabpanel"
                                aria-labelledby="pills-profile-tab"
                            >
                                <div className="row ">
                                    <ProfileSideTab />
                                    <div className="col-lg-9">
                                        <div className="tab-content" id="pills-tabContent">
                                            <PersonalInfo />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="card card-back tab-pane fade"
                                id="pills-settings"
                                role="tabpanel"
                                aria-labelledby="pills-settings-tab"
                            >
                                <div className="row ">
                                    <BusinessProfileTab />
                                    <div className="col-lg-9">
                                        <div className="tab-content" id="pills-tabContent">
                                            <BusinessInfo location={location} getMapLocation={getMapLocation}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Settings;