import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";


const usePostFetch=(url,reload)=>{
    const navigate=useNavigate()
    const [data,setData]=useState(null);
    const [isloaded,setIsLoaded]=useState(false);
    const [errors,setErrors]=useState(null);

    useEffect(() => {
        axios
          .post(url)
          .then((res) => {
            if (res.data.status === 200) {
              setIsLoaded(true);
              setData(res.data.data);
            }
          })
          .catch((err) => {
            setIsLoaded(true)
            setErrors(err?.response);
            console.log(err?.response);
            if (err.response.status === 401) {
              localStorage.clear();
              navigate("/login");
            }else{
              Swal.fire({
                title:'Error',
                text:'Error Loading Records',
                icon:'error',
                confirmButtonColor:'#338540'
              })
            }
          });
      }, [url,reload]);

      return {data,isloaded,errors}
}

export default usePostFetch;