import CardCollection from "./../dashboard/components/CardCollection";
import EmployeeTable from "./components/EmployeeTable";
import { useEffect, useState, React } from "react";
import axios from "axios";

const Home = () => {
  const [employees, setEmployees] = useState([]);
  const [reloadTable, setReloadTable] = useState(true);
  const [isLoading,setLoading]=useState(false)
  const [page,setPage]=useState(1);
  const [pageCount,setPageCount]=useState();
  const handlePageClicked = (e) => {
    setPage(e.selected+1); 
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get("/prime.sika/v1/users/merchant/details")
      .then((res) => {
        localStorage.setItem(
          "organization_code",
          res?.data?.data?.user_details?.person?.organization?.code
        );

        axios
          .get(
            `/prime.sika/v1/plus/employees/${localStorage.getItem(
              "organization_code"
            )}/list?page=${page}`
          )
          .then((res) => {
            if (res.data.status === 200 && res.data.success === true) {
              setLoading(false);
              setEmployees(res.data.data.employees);
              // setReloadTable(!reloadTable);
              setPageCount(res?.data?.data?.total_pages);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err.response);
          });
      })
      .catch((err) => {
        console.log(err.response);
      });

    // }else{

    //     axios
    //     .get(
    //       `/prime.sika/v1/plus/employees/${localStorage.getItem(
    //         "organization_code"
    //       )}/list`
    //     )
    //     .then((res) => {
    //       if (res.data.status === 200 && res.data.success === true) {
    //         setEmployees(res.data.data.employees);
    //         setReloadTable(!reloadTable);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err.response);
    //     });
    // }
  }, [reloadTable,page]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <CardCollection reloadTable={reloadTable} />

          <div className="row mt-3">
            {/* <Activity /> */}
            <EmployeeTable
              employees={employees}
              setEmployees={setEmployees}
              reloadTable={reloadTable}
              setReloadTable={setReloadTable}
              isloading={isLoading}
              page={page}
              pageCount={pageCount}
              handlePageClick={handlePageClicked}
            />
          </div>

          {/* <div className="row mt-5 ">
            <div className="col-lg-12 w-100 p-0">
              <div className="card card-upgrade-account">
                <div className="d-flex flex-row justify-content-between">
                  <div className="p-2">
                    <div className="ms-5 mt-5">
                      <div className="d-flex flex-column justify-content-between">
                        <div>
                          <h2 className="subtitle2 font-x-large text-left">
                            Get more <span>effiecient service</span>
                          </h2>
                        </div>
                        <div className="mt-2">
                          <p>
                            Upgrade your account to <b>Gold</b>
                          </p>
                        </div>
                        <div>
                          <div className="btn btn-outline-success">
                            Upgrade your account
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <img src="/assets/images/subscribe.png" width="100%" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <div className="col-lg-4">
          {/* <Profile /> */}
          {/* <SubscriptionCard /> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
