import React from "react";

const Card = ({image,value,title,subtitle,background,setTableTitle}) => {
  return (
    <div className="col-12 mb-2" onClick={()=>setTableTitle(title)}>
      <div className={background}>
        <div className="circle1"></div>
        <div className="card-circle2"></div>
        <div className="card-body z-position">
          <div className="row">
            <div className="col-2">
              <div className="icon-background">
                <img src={image} alt="" width="20px" />
              </div>
            </div>
            <div className="col-9">
              <h3 className="card_widget_header">{value}</h3>
            </div>
            <div className="col-12">
              <h6 className="text-mute">{subtitle}</h6>
            </div>
            <div className="col-12">
              <h6 className="text-mute1">{title}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
