import React, { useEffect, useState } from "react";
import axios from "axios";
import PurchasedCards from "./egiftWallet/PurchasedCards";
import AssignCardToEmployee from "./egiftWallet/AssignCardToEmployee";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import AssignedCards from "./egiftWallet/AssignedCards";
import LoadingPage from "../common/LoadingPage";

const EGiftWallet = () => {
  const navigate = useNavigate();
  const [cards, setCards] = useState([]);
  const [loadedCards, setLoadedCards] = useState([]);
  const [cardId, setCardId] = useState();
  const [selectedCards, setSelectedCards] = useState([]);
  const [reload, toggleReload] = useState(false);
  const [screen, setScreen] = useState("employee_list");
  const [isloaded,setLoaded]=useState(false);

  useEffect(() => {
    axios
      .get(
        "/prime.sika/v1/plus/accounts/customer/bought-cards/list?link_state[]=UNLINKED"
      )
      .then((res) => {
        setCards(res.data.data.card_accounts);
        setLoadedCards(res.data.data.card_accounts);
        setLoaded(true);
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 &&
          err?.response?.data?.success === false &&
          err?.response?.data?.error_msg === "Unauthorized"
        ) {
          localStorage.removeItem("token");
          navigate("/login");
        }
      });
  }, [reload]);

  const [userInput, setUserInput] = useState({
    employee_id: "",
    message: "",
    scheduled_date: "",
    use_dob: false,
    occasion_type:""
  });
 

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };


  return (
    <>
    {
      isloaded?
      <div className="col-lg-12">
        <div className="card card-bord p-3">
          <div className="col-lg-12">
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <div className="e-wallet-text">
                  <h4>e-Gift Wallet</h4>
                </div>
                <div className="card-list-font">
                  <h5>
                    List of cards purchased, ready to be
                    sent out
                  </h5>
                </div>
              </div>
              <div className="d-flex div-width-height">
                <h5>Total:</h5>
                <h4>¢0.00</h4>
              </div>
            </div>
            <PurchasedCards loadedCards={loadedCards} setCardId={setCardId} setScreen={setScreen}/>
            <AssignedCards reload={reload} toggleReload={toggleReload} />
          </div>
        </div>
        <AssignCardToEmployee
          reload={reload}
          toggleReload={toggleReload}
          handleUserInput={handleUserInput}
          userInput={userInput}
          setUserInput={setUserInput}
          screen={screen}
          setScreen={setScreen}
          cardId={cardId}
        />
      </div>
      :<div className="d-flex justify-content-center">
        <LoadingPage/>
      </div>
    }
    </>
  );
};
export default EGiftWallet;
