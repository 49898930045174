import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const Header = () => {
  const capitalize = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };
  
  return (
    <>
      <div className="header">
        <div className="row top-bar-mobile justify-content-between">
          <div className="col-4">
            <button className="mobile_btn" id="mobile_btn">
              {" "}
              <i className="fas fa-bars"></i>{" "}
            </button>
          </div>
          <div className="col-8">
            <div className="row">
              <div className="col-4">
                <div className="notification-icon-cover1">
                  <a href="#">
                    <img
                      src="assets/img/notification.png"
                      alt=""
                      className="notification-icon-position1"
                    />
                  </a>
                </div>
              </div>
              <div className="col-8">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="white"
                    className="d-flex admin_content"
                  >
                    <div className="image">
                      <img src='/assets/images/dashboard/placeholder.jpg' alt="" />
                    </div>
                    <div className="admin_name">
                      <h4 className="my-auto mx-2">
                        Test
                      </h4>
                    </div>
                    <div className="admin_dropdownicon">
                      <img src="assets/img/arrow-down.png" alt="" />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Link to="/settings">Settings</Link>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item>
                      <button onClick={()=>{}}>Logout</button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
