import React, { useRef, useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const TransactionHistoryInvoice = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Invoice",
    onAfterPrint: () => alert("Print Success"),
  });

  const [transaction, setTransaction] = useState([]);
  const [profile, setProfile] = useState([]);
  const navigate = useNavigate();

  const formatedDate = new Date(transaction?.created_at).toLocaleString(
    "en-US",
    {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }
  );

  const { id } = useParams();
  useEffect(() => {
    axios
      .post("/prime.sika/v1/plus/business_customer/payments-reports", {
        payment_ids: [Number(id)],
      })
      .then((res) => {
        if (res.data.status === 200 && res.data.success === true) {
          setTransaction(res?.data?.data?.payments[0]);
        }
      })
      .catch((err) => {
        console.log(err?.response);
        if (err.response?.data?.status === 401) {
          navigate("/login");
        }
      });

    axios
      .get("/prime.sika/v1/users/customer/details")
      .then((res) => {
        if (res.data.status === 200 && res.data.success === true) {
          setProfile(res?.data?.data?.user_details);
        }
      })
      .catch((err) => {
        console.log(err?.response);
      });
  }, []);

  return (
    <>
      <div className="container p-5">
      <button
        className="btn btn-success mb-2"
        onClick={() => {
          navigate("/dashboard/recent-activity");
        }}
      >
        Back
      </button>
        <div
          className="row invoice-container"
          ref={componentRef}
          style={{ width: "100%", padding: "10px" }}
        >
          <div className="col-lg-12">
            <div className="invoice-font">
              <h3>INVOICE</h3>
            </div>
            <hr />
          </div>
          <div className="col-lg-12 mb-3">
            <div className="row justify-content-between">
              <div className="col-lg-4">
                <img src="/assets/images/logo.png" alt="" width="40%" />
              </div>
              <div className="col-lg-4 d-block">
                <div className="zep-font">
                  <h4>Prime E-Gift Card</h4>
                  <h5>
                    <span>www.primeegiftcard.com</span> info@primeegiftcard.com
                    <span>+233 247 117 448</span>
                    +233 207 223 021
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 bg-color mb-3">
            <div className="in-font">
              <h4>Invoice #{transaction?.ref}</h4>
              <h5>
                Issued Date: <span>{formatedDate}</span>
                {/* Issued Date: <span>{new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(cart?.created_at)}</span> */}
              </h5>
            </div>
          </div>
          <div className="col-lg-12 py-3">
            <div className="d-flex justify-content-between">
              <div className="in-font">
                <h4>Invoice To:</h4>
                <h5 className="text-capitalize">
                  {profile?.person?.organization?.name}
                </h5>
                <h5>{profile?.person?.organization?.telephones[0]}</h5>
                <h5>{profile?.person?.organization?.email}</h5>
              </div>
              <div>
                {/* <img src="/assets/images/logo.png" alt="" width="70%" /> */}
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table
              className="datatable table table-stripped table table-hover table-center mb-5"
              border="1"
            >
              <thead>
                <tr>
                  <th>Description</th>
                  <th></th>
                  <th></th>
                  <th className="text-center">Quantity</th>
                  <th></th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>E-gift Cards</td>
                  <td></td>
                  <td></td>
                  <td className="text-center">
                    {transaction?.cart?.cards?.length}
                  </td>
                  <td></td>
                  <td>GH¢ {transaction?.cart?.net_amount}</td>
                </tr>
                <tr>
                  <td>Service Charges</td>
                  <td></td>
                  <td></td>
                  <td className="text-center">1</td>
                  <td></td>
                  <td>GH¢ {transaction?.cart?.fees}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className=""></td>
                  <td></td>
                  <td></td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="text-center">Invoice Total:</td>
                  <td></td>
                  <td>
                    {" "}
                    <h4>GH¢ {transaction?.cart?.total_amount}</h4>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* <hr /> */}
            <table
              className="datatable table table-stripped table table-hover table-center mt-3"
              border="1"
            >
              <thead>
                <tr>
                  <th colSpan="2">
                    <h4>Payment Status</h4>
                  </th>
                  <th></th>
                  <th className="text-capitalize">
                    {transaction?.cart?.cart_status}
                  </th>
                </tr>
              </thead>
              {/* <tbody>
                <tr>
                  <td colSpan="5">
                    You may pay to our Bank Account using invoice number as your
                    reference
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <PaymentOptionRows
                  title="Bank Name"
                  value="Guaranty Trust Bank (Ghana) Limited"
                />
                <PaymentOptionRows
                  title="Bank Address"
                  value="25A, CASTLE ROAD AMBASSADORIAL AREA, RIDGE PMB CT416 CANTONMENTS 
ACCRA ACCRA GHANA"
                />
              
                <PaymentOptionRows title="Branch" value="Spintex Branch" />
                <PaymentOptionRows
                  title="Account Name"
                  value="Jackbuild Limited"
                />
                <PaymentOptionRows
                  title="Account No."
                  value="205-126507-110 - GHS"
                />
                <PaymentOptionRows title="Sort Code" value="230102" />
                <PaymentOptionRows title="BIC (Swift)" value="GTBIGHAC" />
               
              </tbody> */}
            </table>
          </div>
        </div>
        <button
          className="btn btn-success float-end mr-2 mt-4"
          onClick={handlePrint}
        >
          Print
        </button>
      </div>
    </>
  );
};
export default TransactionHistoryInvoice;

const PaymentOptionRows = ({ title, value, colSpan }) => {
  return (
    <tr>
      <td className="td-border-right" colSpan={colSpan}>
        {title}
      </td>
      <td>{value}</td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );
};
