import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import PageHeader from "./components/PageHeader";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState,React } from "react";
import axios from "axios";

const DashboardLayout = () => {
  const [user, setUser] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  });

  useEffect(() => {
    axios
      .get("/prime.sika/v1/users/customer/details")
      .then((res) => {
        if (res?.data?.success === true && res?.data?.status === 200) {
          localStorage.setItem(
            "organization_code",
            res?.data?.data?.user_details?.person?.organization?.code
          );
          setUser(res?.data?.data);
        }
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 &&
          err?.response?.data?.success === false &&
          err?.response?.data?.error_msg === "Unauthorized"
        ) {
          localStorage.removeItem("token");
          navigate("/login");
        }
      });
  }, []);

  return (
    <div className="main-wrapper">
      <Header />
      <Sidebar />
      <div className="page-wrapper pb-5">
        <div className="content container-fluid">
          <PageHeader user={user} />
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
