import axios from "axios";
import React,{useState} from "react";
import Swal from "sweetalert2";
import { Circles } from "react-loader-spinner";

const ViewEmployeeTableRow = ({employee,index,group_id,selectedGroup,reload,setReload,refresh,setRefresh}) => {
    const [processing,setProcessing]=useState('false');

    const handleDelete=(e)=>{
        e.preventDefault();
        setProcessing('true');
      let  data={
            employee_ids:[e.target.value],
            group_id:selectedGroup
        }
        axios.delete('/prime.sika/v1/plus/groups/remove-members',{data:data})
        .then(res=>{
            if(res.data.success===true && res.data.status===200){
                setProcessing('false');
                setReload(!reload);
                setRefresh(!refresh);
            }
        }).catch(err=>{
            console.log(err.response);
            setProcessing('false');
            Swal.fire({
                title:'Failed',
                text:'Error Deleting Employee',
                icon:'error',
                confirmButtonColor:'#ff0000'
            })
        })
    }

    var actionButton;
  if (processing === "false") {
    actionButton = (
        <button className="btn btn-danger" onClick={handleDelete} value={employee?.id}>Remove</button>
    );
  } else if (processing === "true") {
    actionButton = (
      <div className="d-flex justify-content-center">
        <Circles
          height="30"
          width="30"
          color="#4fa94d"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />{" "}
        <span>&nbsp; Unassigning</span>
      </div>
    );
  } else if (processing === "done") {
    actionButton = (
      <div className="d-flex ">
          <button className="btn btn-success" disabled>Assigned</button>
      </div>
    );
  }

  return (
    <tr key={index}>
      <td>{employee?.fullname ?? "N/A"}</td>
      <td>{employee?.telephone}</td>
      <td className="text-capitalize">{employee?.position}</td>
      <td>
        {actionButton}
      </td>
    </tr>
  );
};

export default ViewEmployeeTableRow;
