import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useEffect, useState,React } from "react";
// import CardLoading from "./../../../partials/CardLoading";
import { Skeleton } from "@mui/material";

const CardSlider = ({ card, amountArr, isAmountSelected }) => {
  var settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true,
  };

  const [cardAmounts, setCardAmounts] = useState([]);
  useEffect(() => {
    setCardAmounts(amountArr);
  }, [setCardAmounts, amountArr]);

  if (isAmountSelected === false) {
    return (
      <div className="col-md-11">
        <div className="mb-4">
          <div className="outer-div1">
            {card?.image ? (
              <img
                src={card?.image}
                alt="Product"
                width="100%"
              />
            ) : (
              <Skeleton variant="rectangular" width={500} height={300}/>
            )}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="col-md-11">
      <Slider {...settings}>
        {cardAmounts.map((value, index) => {
          return (
            <div className="mb-4" key={index}>
              <div className="outer-div">
                <img
                  src={card?.image}
                  alt="Product"
                  width="100%"
                />
                <div className="inner-div text-center">
                  <div className="text-infront-subtext text-dark">
                    Card Amount
                  </div>
                  <div className=".text-infront-subtext text-center text-green">
                    ¢{value}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default CardSlider;
