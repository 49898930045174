import { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const AddEmployee = ({ reloadTable, setReloadTable }) => {
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();

  const [userInput, setUserInput] = useState({
    country_code_name: "GH",
    first_name: "",
    surname: "",
    gender: "",
    employee_code: "N/A",
    dob: "2015-10-10",
    day: "",
    month: "",
    email: "",
    telephone: "",
    employee_position: "",
  });

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const [months, setMonths] = useState([
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ]);
  const [days, setDays] = useState([]);
  useEffect(() => {
    for (let i = 1; i <= 31; i++) {
      i < 10
        ? setDays((days) => [...days, `0${i}`])
        : setDays((days) => [...days, `${i}`]);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    var data = {
      country_code_name: "GH",
      first_name: userInput.first_name,
      surname: userInput.surname,
      gender: userInput.gender,
      employee_code: "N/A",
      dob: `${userInput?.day}-${userInput?.month}`,
      telephone: phone,
      email: userInput.email,
      employee_position: userInput.employee_position,
    };
    userInput.email === "" &&
      (data = {
        country_code_name: "GH",
        first_name: userInput.first_name,
        surname: userInput.surname,
        gender: userInput.gender,
        employee_code: "N/A",
        dob: `${userInput?.day}-${userInput?.month}`,
        telephone: phone,
        employee_position: userInput.employee_position,
      });

    axios
      .post(
        `/prime.sika/v1/plus/employees/${localStorage.getItem(
          "organization_code"
        )}/add`,
        data
      )
      .then((res) => {
        if (res.data.status === 201 && res.data.success === true) {
          setReloadTable(!reloadTable);
          Swal.fire({
            title: "Success",
            text: "Employee has been registered successfully",
            icon: "success",
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: "Okay",
            confirmButtonColor: "#00b17d",
            denyButtonText: `No`,
          }).then(() => {
            navigate("/dashboard");
          });
        }
      })
      .catch((err) => {
        console.log(err);
        if (
          err.response.data.status === 400 &&
          err.response.data.success === false
        ) {
          Swal.fire({
            title: "Failed",
            text: err.response.data.error_msg,
            icon: "warning",
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: "Okay",
            confirmButtonColor: "#00b17d",
            denyButtonText: `No`,
          });
        }
      });
  };
  return (
    <div
      className="modal fade"
      id="add_employee_modal"
      aria-hidden="true"
      role="dialogs"
      tabIndex="-1"
      aria-labelledby="add_employee_modal"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body ">
            <div className="">
              <h3 className="contact-text">Add Employee</h3>
            </div>
            <hr />
            <form action="" onSubmit={handleSubmit}>
              <div className="mt-3 mb-3">
                <label htmlFor="fullname" className=" footer-text">
                  First Name <sup className="text-success">*</sup>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="first_name"
                  placeholder="Enter employee's first name"
                  value={userInput.first_name}
                  onChange={handleUserInput}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="fullname" className=" footer-text">
                  Surname <sup className="text-success">*</sup>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="surname"
                  placeholder="Enter employee's surname"
                  value={userInput.surname}
                  onChange={handleUserInput}
                  required
                />
              </div>
              <div className="arr-dwn mb-3">
                <label htmlFor="category" className="footer-text">
                  Gender <sup className="text-success">*</sup>
                </label>
                <select
                  className="form-control"
                  name="gender"
                  onChange={handleUserInput}
                  value={userInput.gender}
                  required
                >
                  <option value="">-- Select Gender --</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
                <img
                  src="assets/images/arrowdown.png"
                  alt=""
                  className="arr-dwn-pos"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="dob" className="footer-text">
                  DOB (optional)
                </label>
                <div className="row">
                  <div className="col">
                    <select
                      className="form-control"
                      name="day"
                      onChange={handleUserInput}
                      value={userInput.day}
                    >
                      <option value="">-- Select Day --</option>
                      {days.map((day, index) => {
                        return (
                          <option value={day} key={index}>
                            {day}
                          </option>
                        );
                      })}
                    </select>
                    <img
                      src="assets/images/arrowdown.png"
                      alt=""
                      className="arr-dwn-pos"
                    />
                  </div>
                  <div className="col">
                    <select
                      className="form-control"
                      name="month"
                      onChange={handleUserInput}
                      value={userInput.month}
                    >
                      <option value="">-- Select Month --</option>
                      {months.map((month, index) => {
                        return (
                          <option
                            value={month}
                            key={index}
                            className="text-capitalize"
                          >
                            {month}
                          </option>
                        );
                      })}
                    </select>
                    <img
                      src="assets/images/arrowdown.png"
                      alt=""
                      className="arr-dwn-pos"
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="footer-text">
                  Phone Number <sup className="text-success">*</sup>
                </label>
                <PhoneInput
                  className="form-control"
                  placeholder="Enter your phone number here (Optional)"
                  defaultCountry="GH"
                  value={phone}
                  name="phone"
                  onChange={setPhone}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="footer-text">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={userInput.email}
                  onChange={handleUserInput}
                  placeholder="Employee's Email Address"
                />
              </div>
              <div className="arr-dwn mb-3">
                <label htmlFor="category" className="footer-text">
                  Employee Position
                </label>
                <select
                  className="form-control"
                  name="employee_position"
                  onChange={handleUserInput}
                  required
                >
                  <option value="">-- Select Employee Position --</option>
                  <option value="executive">Executive</option>
                  <option value="manager">Manager</option>
                  <option value="staff">Staff</option>
                </select>
                <img
                  src="assets/images/arrowdown.png"
                  alt=""
                  className="arr-dwn-pos"
                />
              </div>
              {/* <div className="mb-3">
                <label htmlFor="email" className="footer-text">
                  Employee Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="employee_code"
                  onChange={handleUserInput}
                  value={userInput.employee_code}
                  placeholder="Employee's code"
                />
              </div> */}

              <button
                type="submit"
                className="btn btn-outline-success form-control"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="btn-label">
                  <i className="fa fa-plus" />
                </span>{" "}
                &nbsp; Save and Close
              </button>
            </form>
            {/* <div className="card-body">
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEmployee;
