import { Link } from "react-router-dom";
import React from "react";

const PersonalInfo = () => {
  // const [userDetails,setUserDetails]=useState([]);

  return (
    <>
      <div
        className="tab-pane fade show active"
        id="pills-personal"
        role="tabpanel"
        aria-labelledby="pills-personal-tab"
      >
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-chart2 card-h10 card-h10-pad">
              <div>
                <h3>Personal Information</h3>
                <hr className="hr3" />
              </div>
              <div>
                <h4>
                  We do not sell or share your details without your permission.
                  Find out more in our <Link to="">Privacy Policy.</Link>
                  <span>
                    Your{" "}
                    <b>firstname, lastname and telephone</b>{" "}
                    can be updated via{" "}
                    <b className="merch-color">
                      PrimePlus Personal Information Settings
                    </b>
                  </span>
                </h4>
              </div>
            </div>
          </div>

          <div className="col-lg-12 mt-3">
            <form className="card card-chart2 card-h11 card-h10-pad">
              <div>
                <h3>Your Details</h3>
                <hr className="hr3" />
              </div>
              <div className="ml-4">
                <div className="form-group d-flex align-items-center justify-content-between mt-3">
                  <div className="form-name-width">
                    <label htmlFor="name">
                      <h4 className="my-auto">First Name</h4>
                    </label>
                  </div>
                  <div className="form-box-width">
                    <input
                      type="text"
                      className="form-control form-fill-textbox1"
                      name="first_name"
                    />
                  </div>
                </div>
                <div className="form-group d-flex align-items-center mt-4">
                  <div className="form-name-width">
                    <label htmlFor="name">
                      <h4 className="my-auto">Last Name</h4>
                    </label>
                  </div>
                  <div className="form-box-width">
                    <input
                      type="text"
                      className="form-control form-fill-textbox1"
                      name="surname"
                    />
                  </div>
                </div>
                <div className="form-group d-flex align-items-center mt-4">
                  <div className="form-name-width">
                    <label htmlFor="name">
                      <h4 className="my-auto">Telephone</h4>
                    </label>
                  </div>
                  <div className="form-box-width">
                    <input
                      type="text"
                      className="form-control form-fill-textbox1"
                      name="phone_number"
                    />
                  </div>
                </div>
                <div className="mt-5">
                <button
                    type="submit"
                    className="btn btn-dark float-end save-btn-size"
                    value="Save"
                  >Save</button>
                  {/* {isloading && <button
                    className="btn btn-success float-right save-btn-size"
                    disabled
                  >
                    <i className="fas fa-spinner fa-spin mr-2"></i>Saving
                  </button>} */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalInfo;
