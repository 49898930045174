import axios from "axios";
import React, { useEffect, useState } from "react";
import { Circles } from "react-loader-spinner";
import Swal from "sweetalert2";
import FileUploadForms from "./FileUploadForms";

const AddNewGroup = ({ setReload, reload }) => {
  const [userInput, setUserInput] = useState({
    title: "",
  });
  const [processing, setProcessing] = useState("false");
  const [addFileUpload, setAddFileUpload] = useState(false);

  const handleAddFileUpload = (e) => {
    setAddFileUpload(!addFileUpload);
  };

  var actionButton;
  if (processing === "false") {
    actionButton = (
      <button
        type="submit"
        className="btn btn-outline-success form-control"
        // data-bs-dismiss="modal"
        // aria-label="Close"
      >
        <span className="btn-label">
          <i className="fa fa-plus" />
        </span>{" "}
        &nbsp; Create Group
      </button>
    );
  } else if (processing === "true") {
    actionButton = (
      <div className="d-flex justify-content-center">
        <Circles
          height="30"
          width="30"
          color="#4fa94d"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />{" "}
        <span>&nbsp; processing...</span>
      </div>
    );
  } else if (processing === "done") {
    actionButton = (
      <div className="d-flex ">
        <button
          type="submit"
          className="btn btn-outline-success form-control"
          // data-bs-dismiss="modal"
          // aria-label="Close"
        >
          <span className="btn-label">
            <i className="fa fa-plus" />
          </span>{" "}
          &nbsp; Create New Group
        </button>
        <div className="mx-2"></div>
        <button
          type="submit"
          className="btn btn-success form-control"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            setProcessing("false");
          }}
        >
          <span className="btn-label">
            {/* <i className="fa fa-check-icon" /> */}
          </span>{" "}
          &nbsp; Close
        </button>
      </div>
    );
  }

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    // console.log(e);
    setProcessing("true");
    e.preventDefault();
    axios
      .post("/prime.sika/v1/plus/groups", { name: userInput.title })
      .then((res) => {
        setReload(!reload);
        if (res.data.status === 201 && res.data.success === true) {
          setUserInput((userInput) => ({
            ...userInput,
            title: "",
          }));
          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Group has been created successfully",
            confirmButtonText: "Okay",
            confirmButtonColor: "#00b17d",
            timer:2000
          }).then(() => {
            setProcessing("done");
            setUserInput((userInput) => ({
              ...userInput,
              name: "",
            }));
          });
        }
      })
      .catch((err) => {
        console.log(err.response);
        setProcessing("false");
        if (
          err.response.data.success === false &&
          err.response.data.status === 400
        ) {
          let msg = err.response.data.error_msg;
          Swal.fire({
            title: "Error",
            icon: "error",
            text: msg,
            confirmButtonText: "Okay",
            confirmButtonColor: "#D0312D",
          });
        }
      });
  };

  return (
    <div
      className="modal fade"
      id="add_employee_to_group_modal"
      aria-hidden="true"
      role="dialogs"
      tabIndex="-1"
      aria-labelledby="add_employee_to_group_modal"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body ">
            <div className="">
              <h3 className="contact-text">Add Group</h3>
            </div>
            <hr />
            {!addFileUpload && (
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="email" className="footer-text">
                    Group Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    placeholder="Enter group title here"
                    value={userInput.title}
                    onChange={handleUserInput}
                    required
                  />
                </div>
                {actionButton}
              </form>
            )}

            <div className="d-flex  mt-3 ms-3 check-text-size">
              <div className="form-group">
                <input
                  type="checkbox"
                  className="checkbox-design"
                  required=""
                  name="special_off"
                  id="special_off"
                  value={addFileUpload}
                  onChange={handleAddFileUpload}
                />
                <label htmlFor="special_off">
                  <h4 className="ms-1">Upload Employees to Group</h4>
                </label>
              </div>
            </div>

            {addFileUpload && (
              <FileUploadForms
                userInput={userInput}
                setUserInput={setUserInput}
                handleUserInput={handleUserInput}
                reload={reload}
                setReload={setReload}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddNewGroup;
