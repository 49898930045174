import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

const PurchasedCards = ({ loadedCards, setCardId, setScreen }) => {
  const [cardChecked, setCardChecked] = useState(false);
  const [radioStatus, setRadioStatus] = useState(false);
  const toggleChecked = (id) => {
    setRadioStatus(!radioStatus);
    setCardId(id);
  };

  return (
    <div className="row mt-4">
      <div className="col-lg-12">
        <div className="card-cover pr-4">
          {loadedCards.map((item, i) => {
            return (
              <div key={i} className="card card-w">
                <label htmlFor={i}>
                  <img src={item?.card?.image} alt="" />
                  <div className="tit-font">
                    <h4>{item?.card?.title}</h4>
                  </div>
                  <div className="amt-font">
                    <h4>GH¢ {item?.card?.amount}</h4>
                  </div>
                  <div className="check-pos">
                    <input
                      type="radio"
                      name="radio-phone"
                      id={i}
                      className="radio__input mr-1"
                      onChange={(e) => {
                        toggleChecked(item.account_id);
                        setScreen("employee_list");
                        setCardChecked(true);
                      }}
                    />
                    <div className="radio__radio1"></div>
                  </div>
                </label>
              </div>
            );
          })}
        </div>
      </div>

      {loadedCards?.length > 0 ? (
        <div className="col-lg-12 mt-3">
          {cardChecked === true ? (
            <button
              onClick={() => setScreen("employee_list")}
              data-bs-toggle="modal"
              data-bs-target="#swap_employee_modal"
              className="btn-success form-control des-text"
            >
              <h4 className="my-auto">
                Click here to assign an employee to card selected
              </h4>
            </button>
          ) : (
            <div className="des-text1">
              <h4 className="my-auto">Click on a card to assign an employee</h4>
            </div>
          )}
        </div>
      ) : (
        <div className="des-text1 mt-3">
          <h2 className="my-auto">No Card is Available at the Moment</h2>
        </div>
      )}
    </div>
  );
};

export default PurchasedCards;
