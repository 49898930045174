import { useState, useEffect } from "react";
import axios from "axios";
import EmployeeListScreen from "./EmployeeListScreen";
import Schedule from "./Schedule";

const AssignCardToEmployee = ({
  cardId,
  handleUserInput,
  userInput,
  setUserInput,
  screen,
  setScreen,
  reload,toggleReload
}) => {
 
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    axios
      .get(
        `/prime.sika/v1/plus/employees/${localStorage.getItem(
          "organization_code"
        )}/list`
      )
      .then((res) => {
        if (res.data.status === 200 && res.data.success === true) {
          setEmployees(res.data.data.employees);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [reload]);

  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("");

  return (
    <div
      className="modal fade"
      id="swap_employee_modal"
      aria-hidden="true"
      role="dialogs"
      tabIndex="-1"
      aria-labelledby="swap_employee_modal"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body ">
            {screen === "employee_list" && (
              <EmployeeListScreen
                setScreen={setScreen}
                setSearch={setSearch}
                setCategory={setCategory}
                userInput={userInput}
                setUserInput={setUserInput}
                handleUserInput={handleUserInput}
                employees={employees}
                search={search}
                category={category}
                // assignGiftCard={assignGiftCard}
              />
            )}
            {screen === "schedule" && (
              <Schedule
                setScreen={setScreen}
                userInput={userInput}
                handleUserInput={handleUserInput}
                reload={reload}
                toggleReload={toggleReload}
                cardId={cardId}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AssignCardToEmployee;
