import { useEffect, useState, React } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";

const LoginForm = () => {
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState({
    email: "",
    password: ""
  });

  const [errors, setErrors] = useState({
    email: "",
    password: ""
  });

  const [isProcessing, setProcessing] = useState(false);

  useEffect(() => { }, [isProcessing]);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value
    }));
  };

  const login = () => {
    const data = {
      forname: userInput.email,
      password: userInput.password,
      user_type: "business_customer"
    };
    localStorage.removeItem("token");
    axios
      .post("/prime.sika/v1/users/login", data)
      .then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("token", res.data.data.token);
          axios.get('/prime.sika/v1/users/merchant/details').then(res => {
            localStorage.setItem('organization_code', res?.data?.data?.user_details?.person?.organization?.code)
            localStorage.setItem('organization',res?.data?.data?.user_details?.person?.organization?.name);
            localStorage.setItem('organization_id',res?.data?.data?.user_details?.person?.organization?.id);
          }).catch(err => {
            console.log(err.response)
          })
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        if (
          err.response.data.status === 400 &&
          err.response.data.success === false
        ) {
          setErrors((errors) => ({
            ...errors,
            email: err.response.data.error_msg
          }));
          setProcessing(false);
        } else if (
          err.response.data.status === 401 &&
          err.response.data.success === false
        ) {
          setErrors((errors) => ({
            ...errors,
            email: err.response.data.error_msg
          }));
          setProcessing(false);
        } else {
          console.log(err);
          setProcessing(false);
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (userInput.email === "" || !userInput.email) {
    //   setErrors((error) => ({
    //     ...error,
    //     email: "Please Enter your email address",
    //   }));
    //   setProcessing(false)
    // } else if (
    //   !userInput.email.includes("@") ||
    //   !userInput.email.includes(".")
    // ) {
    //   setErrors((error) => ({
    //     ...error,
    //     email: "Please Enter a valid email address",
    //   }));
    //   setProcessing(false)
    // } else if (userInput.password === "" || !userInput.password) {
    //   setErrors((error) => ({
    //     ...error,
    //     password: "Please Enter your password",
    //   }));
    //   setProcessing(false)
    // } else {
    //   login();
    // }
    login();
  };

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group mt-3">
        <input
          type="text"
          className="textbx-size"
          placeholder="Enter your email or phone number"
          value={userInput.email}
          name="email"
          onChange={handleUserInput}
        />
        <div className="img-backg">
          <img
            src="assets/images/sms1.png"
            alt=""
            className="img-fluid img-pos"
          />
        </div>
        <div className="text-danger">{errors.email}</div>
      </div>
      <div className="form-group1 mt-4">
        <input
          type={type}
          className="textbx-size"
          placeholder="Enter your password here"
          value={userInput.password}
          name="password"
          onChange={handleUserInput}
        />
        <div className="img-backg">
          <img
            src="assets/images/lock.png"
            alt=""
            className="img-fluid img-pos"
          />
        </div>
        <span onClick={handleToggle}>
          <Icon
            icon={icon}
            size={18}
            className="view-password-icon1 mr-1 mt-2"
          />
        </span>
        <small className="text-danger">{errors.password}</small>
      </div>
      <div className="content-items mt-4">
        <div className="d-flex">
          <input type="checkbox" className="checkbox-design" id="remember" />
          <label htmlFor="remember">
            <h4>Remember me</h4>
          </label>
        </div>
        <div>
          <Link to="/resetpassword" className="text-success">
            Forgot Password?
          </Link>
        </div>
      </div>

      <button
        type="submit"
        className="btn btn-dark log-in-btn-size "
        {...(isProcessing ? "disabled" : "")}
        onClick={() => setProcessing(true)}
      >
        {isProcessing ? "Please wait..." : "Log In"}
      </button>
    </form>
  );
};

export default LoginForm;
