import Card from "./Card";
import { useEffect, useState,React } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CardCollection = ({reloadTable}) => {
  const navigate = useNavigate();
  const [statistics, setStatistics] = useState([]);

  useEffect(() => {
    axios
      .post("/prime.sika/v1/plus/business_custome/dashboard-report-stats")
      .then((res) => {
        if (res.data.success === true && res.data.status === 200) {
          setStatistics(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (
          err?.response?.status === 401 &&
          err?.response?.data?.success === false &&
          err?.response?.data?.error_msg === "Unauthorized"
        ) {
          localStorage.removeItem("token");
          navigate("/login");
        }
      });
  }, [reloadTable]);
 
  return (
    <div className="row">
      <div className="col-3">
        <Card
          image="/assets/images/card-tick.png"
          value={statistics?.total_bought_cards}
          title="Cards Purchased"
          subtitle=""
          background="card board1 fill5"
        />
      </div>
      <div className="col-3">
        <Card
          image="/assets/images/wallet.png"
          value={statistics?.total_employees}
          title="Total Employees"
          subtitle=""
          background="card board1 fill6"
        />
      </div>
      <div className="col-3">
        <Card
          image="/assets/images/dollar-circle.png"
          value={statistics?.total_active_employees}
          title="Total Active Employees"
          subtitle=""
          background="card board1 fill7"
        />
      </div>
      <div className="col-3">
        <Card
          image="/assets/images/3d-square.png"
          value={statistics?.total_suspended_employees}
          title="Total Suspended Employees"
          subtitle=""
          background="card board1 fill8"
        />
      </div>
    </div>
  );
};

export default CardCollection;
