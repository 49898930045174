import React, { useState } from "react";
import { Link } from "react-router-dom";
import convertDate from "../common/ConverDate";
import usePostFetch from "../common/customHooks/usePostFetch";
import Pagination from "../common/helpers/Pagination";

const RecentActivity = () => {
  const [page, setPage] = useState(1);
  const { data, isloaded, errors } = usePostFetch(
    `/prime.sika/v1/plus/business_customer/payments-reports?page=${page}`,page
  );
 

  const [selectedDate, setDate] = useState(null);
  const filter = data?.payments.filter((item) => {
    if (selectedDate === null) {
      return item;
    } else {
      if ((item?.created_at).substring(0, 10) === selectedDate) {
        return item;
      }
    }
  });

  return (
    <div className="col-lg-12">
      <div className="card card-table card-table-height8">
        <div className="card-header card-header-margin d-block">
          <div className="d-flex justify-content-between">
            <div className="p-2">
              <h3 className="my-auto contact-text">Recent Activities</h3>
            </div>
            <div className="p-2 d-flex no-text-wrap">
              <span className="mr-2 mt-2">Filter Date</span>
              <input
                type={"date"}
                className="form-control"
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="card-body booking_card">
          <div className="table-responsive">
            <table className="datatable table table-stripped table table-hover table-center mb-0 recent-activity">
              <thead>
                <tr>
                  <th>Transaction Type</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {isloaded ? (
                  filter?.map((item) => (
                    <tr>
                      <td className="table-border">
                        <div className="d-flex align-items-center">
                          <div className="note-cover">
                            <img
                              src="/assets/images/dashboard/note.png"
                              alt=""
                            />
                          </div>
                          <div className="date-mg ms-3 pt-2">
                            <h4 className="text-capitalize">
                              {item?.transaction_type.replace("_", " ")}
                            </h4>
                            <h5>{convertDate(item?.created_at)}</h5>
                          </div>
                        </div>
                      </td>
                      <td>GHS ¢{item?.amount}</td>
                      <td className="text-uppercase">
                        {item?.status.replace("_", " ")}
                      </td>
                      <td className="text-uppercase">
                        <Link
                          to={`/dashboard/recent-activity/${item?.id}/invoice`}
                          className="btn btn-success py-1 px-2 view-btn"
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      <h3 className="Text-center">Loading...Please Wait...</h3>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
          <Pagination
            pageCount={data?.total_pages}
            handlePageClick={(e) => setPage(e.selected + 1)}
          />
    </div>
  );
};

export default RecentActivity;
