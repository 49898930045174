// import AssignEmployeeToGroup from "./components/AssignEmployeeToGroup";
import AddNewGroup from "../group/AddNewGroup";
import ViewEmployee from "../group/ViewEmployee";
import React, { useEffect, useState } from "react";
import axios from "axios";
import AssignEmployeeToGroup from "../group/AssignEmployeeToGroup";
import Swal from "sweetalert2";
import GroupEmployeeBulkUpload from "../group/GroupEmployeeBulkUpload";

const Groups = () => {
  const [groups, setGroups] = useState([]);
  const [reload, setReload] = useState(false);
  const [param, setParam] = useState("");
  const [selectedGroup, setSelectedGroup] = useState();
  const [groupName, setGroupName] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get("/prime.sika/v1/plus/groups/list")
      .then((res) => {
        if (res.data.success === true && res.data.status === 200) {
          setLoading(false);
          setGroups(res.data.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
      });
  }, [reload]);

  const handleDelete = (group_id) => {
    Swal.fire({
      title: "Confirmation",
      text: "Are you sure to delete this group?",
      icon: "question",
      confirmButtonText: "Yes",
      showDenyButton: true,
      denyButtonText: "No",
      confirmButtonColor: "#198754",
    }).then((response) => {
      if (response.isConfirmed) {
        axios
          .delete("/prime.sika/v1/plus/groups/dessolve", {
            data: { group_id: group_id },
          })
          .then((res) => {
            if (res.data.status === 200 && res.data.success === true) {
              setReload(!reload);
              let msg = res.data.data.message;
              Swal.fire({
                title: "Completed",
                text: msg,
                icon: "success",
                showConfirmButton: true,
                confirmButtonText: "Okay",
                confirmButtonColor: "#198754",
              });
            }
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    });
  };

  return (
    <>
      <div className="col-lg-12">
        <div className="card card-table">
          <div className="card-header">
            <div className="d-flex flex-row justify-content-between">
              <div className="p-2">
                <h3 className="my-auto contact-text">Manage Groups</h3>
                <h4 className="shop-text-desc2 float-start">
                  {groups?.groups?.length} Groups
                </h4>
              </div>
              <div className="p-2">
                <div className="d-flex">
                  <div className="p-2">
                    <button
                      type="button"
                      className="btn btn-dark"
                      data-bs-toggle="modal"
                      data-bs-target="#add_employee_to_group_modal"
                    >
                      + Add New Group
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="p-1 me-2">
                <input
                  type="text"
                  placeholder="Search for Group Here"
                  className="form-control w-100"
                  name="search"
                  value={param}
                  onChange={(e) => setParam(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="card-table">
            <div className="card-body booking_card">
              <div className="table-responsive">
                <table className="datatable table table-stripped table table-hover table-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-left">Group Name</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Employees</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan={4} className='text-center'><h3>Loading.. Please Wait...</h3></td>
                      </tr>
                    ) : (
                      groups?.groups
                        ?.filter((item) => {
                          if (param !== "") {
                            return item?.name
                              ?.toLowerCase()
                              ?.includes(param.toLowerCase());
                          } else {
                            return item;
                          }
                        })
                        .map((group, i) => (
                          <tr key={i}>
                            <td className="text-capitalize text-left">
                              {group.name}
                            </td>
                            {group?.suspended === false ? (
                              <td className="text-success text-center">
                                <small>Active</small>
                              </td>
                            ) : (
                              <td className="text-danger text-center">
                                <small>Suspended</small>
                              </td>
                            )}
                            <td className="text-center">
                              {group?.employees?.length}
                            </td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <div className="p-1 ms-3">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-success"
                                    data-bs-toggle="modal"
                                    data-bs-target="#assign_employee_modal"
                                    onClick={() => {
                                      setSelectedGroup('');
                                      setSelectedGroup(group?.id)
                                    }}
                                  >
                                    <span>
                                      <img
                                        src="/assets/images/user-add.png"
                                        alt=""
                                        width="15%"
                                        className="me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="View Assigned Employees"
                                      />
                                    </span>
                                    Assign Employees
                                  </button>
                                </div>
                                <div className="p-1 ms-3">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-outline-success"
                                    data-bs-toggle="modal"
                                    data-bs-target="#bulk_modal"
                                    onClick={() => setGroupName(group?.name)}
                                  >
                                    <span>
                                      <img
                                        src="/assets/images/upload.png"
                                        alt=""
                                        width="15%"
                                        className="me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="View Assigned Employees"
                                      />
                                    </span>
                                    Upload Employees
                                  </button>
                                </div>
                                <div>
                                  <button
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#view_employee_modal"
                                    onClick={() => setSelectedGroup(group?.id)}
                                  >
                                    <img
                                      src="/assets/images/eye.png"
                                      alt=""
                                      width="50%"
                                      className="mt-1"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      title="View Assigned Employees"
                                    />
                                  </button>
                                </div>
                                <div>
                                  <button
                                    type="button"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Delete Group"
                                    onClick={() => handleDelete(group?.id)}
                                  >
                                    <img
                                      src="/assets/images/trash.png"
                                      alt=""
                                      width="50%"
                                      className="mt-1"
                                    />
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                    )}
                    {groups?.groups?.length === 0 && (
                      <tr>
                        <td colSpan={4} className="text-center">
                          <h3>No Group Available</h3>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* <AddNewGroup /> */}
        <ViewEmployee
          reload={reload}
          setReload={setReload}
          selectedGroup={selectedGroup}
        />
        <AddNewGroup setReload={setReload} reload={reload} />
        <AssignEmployeeToGroup
          setReload={setReload}
          reload={reload}
          selectedGroup={selectedGroup}
        />
        <GroupEmployeeBulkUpload
          reload={reload}
          setReload={setReload}
          groupName={groupName}
        />
      </div>
    </>
  );
};
export default Groups;
