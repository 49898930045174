import $ from "jquery";
import React from "react";
import NewMap from "../../../dashboard/components/NewMap";

const BusinessDetailForm = ({
  getMapLocation,
  location

}) => {
  $(".form-fill-textbox1")
    .change(function () {
      if ($(this).val() === "0" || $(this).val() === "") {
        $(this).css("border", "2px solid rgb(94, 94, 94, 0.5)");
      } else {
        $(this).css("border", "2px solid rgb(94, 94, 94, 0.8)");
        $(this).css("color", "rgb(94, 94, 94, 0.8)");
      }
    })
    .trigger("change");
  $(".form-fill-textarea1")
    .change(function () {
      if ($(this).val() === "0" || $(this).val() === "") {
        $(this).css("border", "2px solid rgb(94, 94, 94, 0.5)");
      } else {
        $(this).css("border", "2px solid rgb(94, 94, 94, 0.8)");
        $(this).css("color", "rgb(94, 94, 94, 0.8)");
      }
    })
    .trigger("change");

  return (
    <form
      className="card card-chart2 card-h11 card-h10-pad"
      // onSubmit={updateDetails}
    >
      <div>
        <h3>Your Business Details</h3>
        <hr className="hr3" />
      </div>
      <div className="ml-4">
        <div className="form-group d-flex align-items-center mt-4">
          <div className="form-name-width1">
            <label htmlFor="name">
              <h4 className="my-auto">Company Name</h4>
            </label>
          </div>
          <div className="form-box-width1">
            <input
              type="text"
              className="form-control form-fill-textbox1"
              name="name"
              // value={company?.name}
              // onChange={handleCompanyUserInput}
            />
          </div>
        </div>
         {/* Map Modal */}
         <div
            className="modal fade pt-5"
            id="location_modal"
            aria-hidden="true"
            role="dialogs"
            tabindex="-1"
            aria-labelledby="location_modal"
          >
            <div className="modal-dialog " role="document">
              <div className="modal-content moa">
                <div className="modal-body modal-padding">
                  <NewMap getMapLocation={getMapLocation} />
                </div>
              </div>
            </div>
          </div>
          {/* End of Map Modal */}
        <div className="form-group d-flex align-items-center mt-4">
          <div className="form-name-width1">
            <label htmlFor="name">
              <h4 className="my-au">Company's Location Address</h4>
            </label>
          </div>
          <div className="form-box-width1">
            <a data-toggle="modal" href="#location_modal">
              <input
                type="text"
                className="form-control form-fill-textbox1"
                name="address"
                // value={company?.branch || location1?.address}
                // onChange={handleCordinatesChange}
                disabled
              />
              <img
                src="/assets/img/location.png"
                alt=""
                className="company-loc-icon"
              />
            </a>
          </div>
        </div>
        <div className="form-group d-flex align-items-center mt-4">
          <div className="form-name-width1">
            <label htmlFor="name">
              <h4 className="my-auto">Business Email</h4>
            </label>
          </div>
          <div className="form-box-width1">
            <input
              type="text"
              className="form-control form-fill-textbox1"
              name="business_email"
            />
          </div>
        </div>
      </div>
      <div className="mt-5">
        <button
          type="submit"
          className="btn btn-dark float-end save-btn-size"
          value="Save"
        >
          Save
        </button>
        {/* {isloading && <button
          className="btn btn-success float-right save-btn-size"
          disabled
        >
          <i className="fas fa-spinner fa-spin mr-2"></i>Saving
        </button>} */}
      </div>
    </form>
  );
};
export default BusinessDetailForm;
