import CardValue from "./CardValue";
import CardSlider from "./CardSlider";
import axios from "axios";
import { Skeleton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import useGetFetch from "../../common/customHooks/useGetFetch";

const CardDetails = ({
  cards,
  handleSelected,
  userInput,
  handleUserInput,
  appendAmount,
  popAmount,
  amountArr,
  isAmountSelected,
  useDob,
  setUseDob,
  setScreen,
  cart,
  setPurchaseType,
  purchaseType,
}) => {
  const navigate = useNavigate();
  var card_amounts = cards
    .sort((a, b) => a.amount - b.amount)
    .map((item, index) => {
      return (
        <CardValue
          id={item.id}
          amount={item.amount}
          key={index}
          handleSelected={handleSelected}
          appendAmount={appendAmount}
          popAmount={popAmount}
        />
      );
    });

    const {data:occassion}=useGetFetch('/prime.sika/v1/plus/cards/categories?is_occasion=true');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isAmountSelected) {
      alert("Please select at least one card");
    } else {
      if (purchaseType === "later_use") {
        setScreen("preview");
        axios
          .post("/prime.sika/v1/plus/business_customer/carts/create", {
            items: cart,
            buy_for_later: true,
          })
          .then((res) => {
            // console.log(res.data);
          })
          .catch((err) => {
            console.log(err.response);
            if (err.response.data.status === 401) {
              navigate("/login");
            }
          });
      } else {
        setScreen("receipients");
      }
    }
  };

  // use_dob:useDob,
  // scheduled_date:userInput.scheduled_date,
  // message:userInput.message
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 mb-4">
          <Link to="/dashboard/shop">
            <img
              src="/assets/images/arrow-left.png"
              alt=""
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-md-5">
          <CardSlider
            card={cards[0]}
            amountArr={amountArr}
            isAmountSelected={isAmountSelected}
          />

          <div className="row mt-3">
            <div className="subtitle2 text-left text-black">
              <b>Merchant Info</b>
            </div>
            <div className="mt-2">
              <p className="sub-text text-left">{cards[0]?.description}</p>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-5">
              <div className="subtitle2 text-left text-black">Location</div>
              <p className="sub-text text-left">{cards[0]?.client?.location}</p>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-6">
              <div className="subtitle2 text-left text-black">Contact Info</div>
              <p className="sub-text text-left">{cards[0]?.client_telephone}</p>
            </div>
          </div>
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-5 mt-2">
          <div className="container">
            <div className="d-flex flex-row justify-content-between">
              <div className="p-0">
                <h3 className="shop-sub-text text-left text-capitalize">
                  {cards[0]?.title ? (
                    cards[0]?.client_name
                  ) : (
                    <Skeleton variant="text" sx={{ fontSize: "15rem" }} />
                  )}
                </h3>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <div className="row">
              <div className="col">
                <div className="sub-text text-left">
                  <b>Select card value in Ghana Cedis</b>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row mt-3">{card_amounts}</div>
          </div>

          <div className="row mt-3">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12 mt-0">
                  <div className="container">
                    {purchaseType === "for_employees" && (
                      <>
                        <div className="form-group">
                          <label htmlFor="occassion">Select Occassion</label>
                          <select
                            value={userInput?.occassion}
                            onChange={handleUserInput}
                            required
                            className="form-control"
                          >
                            {occassion?.categories?.map((category) => (
                              <option
                                key={category?.id}
                                className="text-capitalize"
                                value={category?.id}
                              >
                                {category?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="sub-text text-left mt-3">
                          <b className="">Add Message</b>
                          <p className="text-faint text-left">
                            Add a personal message or greetings
                          </p>
                        </div>

                        <div className="mt-3">
                          <textarea
                            name="message"
                            cols="30"
                            rows="5"
                            className="text-area"
                            value={userInput.message}
                            onChange={handleUserInput}
                          ></textarea>
                        </div>

                        <div className="check-text-size mt-3">
                          <div className="form-group">
                            <input
                              type="checkbox"
                              className="checkbox-design"
                              name="use_dob"
                              id="use_dob"
                              checked={useDob}
                              onChange={(e) => {
                                setUseDob(!useDob);
                              }}
                            />
                            <label htmlFor="use_dob">
                              <h4 className="ms-1">
                                Use Employee Birthdate as scheduled date
                              </h4>
                            </label>
                          </div>
                        </div>

                        {!useDob && (
                          <div className="form-group">
                            <label htmlFor="scheduled_date">
                              Set custom schedule date
                              <p className="text-faint text-left">
                                Set a date you want card(s) to be delivered
                              </p>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              name="scheduled_date"
                              value={userInput.scheduled_date}
                              onChange={handleUserInput}
                            />
                          </div>
                        )}
                      </>
                    )}

                    <div className="radio-group1 mt-4 d-flex justify-content-between mb-3">
                      <div className="d-flex">
                        <label
                          htmlFor="purchase_for_later_use"
                          className="radio1 d-flex align-items-center"
                        >
                          <input
                            type="radio"
                            name="radio-phone"
                            id="purchase_for_later_use"
                            className="radio__input mr-1"
                            defaultChecked
                            value="later_use"
                            onChange={(e) => setPurchaseType(e.target.value)}
                          />
                          <div className="radio__radio1"></div>
                          <h4 className="my-auto pur-text">
                            Purchase for later use
                          </h4>
                        </label>
                      </div>
                      <div className="d-flex">
                        <label
                          htmlFor="purchase_for_employees"
                          className="radio1 d-flex align-items-center"
                        >
                          <input
                            type="radio"
                            name="radio-phone"
                            id="purchase_for_employees"
                            className="radio__input mr-1"
                            value="for_employees"
                            // defaultChecked
                            onChange={(e) => setPurchaseType(e.target.value)}
                          />
                          <div className="radio__radio1"></div>
                          <h4 className="my-auto pur-text">
                            Purchase and Send
                          </h4>
                        </label>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3 mt-3">
                        <button
                          className="btn btn-success px-5 py-3"
                          type="submit"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default CardDetails;
