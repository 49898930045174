import React from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navList = [
    {
      name: "Dashboard",
      icon: "/assets/img/category.png",
      to: "/dashboard/"
    },
    {
      name: "Purchase Gift Card",
      to: "/dashboard/shop",
      icon: "/assets/img/cards.png"
    },
    {
      name: "My Cart",
      to: "/dashboard/cart",
      icon: "/assets/img/bag.png"
    },
    {
      name: "E-Gift Wallet",
      to: "/dashboard/e-gift-wallet",
      icon: "/assets/img/wallet1.png"
    },
    {
      name: "Track Gift Card",
      to: "/dashboard/track-giftCard",
      icon: "/assets/img/marketing.png"
    },
    {
      name: "Groups",
      to: "/dashboard/groups",
      icon: "/assets/img/profile-2user.png"
    },
    {
      name: "Payment History",
      to: "/dashboard/recent-activity",
      icon: "/assets/img/activity.png"
    },
  ];

  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div className="row align-content-center justify-content-center mt-4">
          <div className="col-10">
            <div className="logo-position">
              <img src="/assets/images/logo.png" width="50%" alt="logo" />
            </div>
          </div>
        </div>
        <hr />

        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            {navList.map((el, index) => (
              <li
                className={`active ${location.pathname === el.to && "active"}`}
                key={index}
              >
                <NavLink to={el.to} className="">
                  <img src={el.icon} alt="" />
                  <span>{el.name}</span>
                </NavLink>
              </li>
            ))}
            {/* <li>
              <NavLink to="/dashboard/shop">
                <img src="/assets/img/bag.png" alt="" />{" "}
                <span>Purchase Gift Card</span>
              </NavLink>{" "}
            </li>
            <li>
              <NavLink to="/dashboard/e-gift-wallet">
                <img src="/assets/img/wallet1.png" alt="" />
                <span>E-Gift Wallet</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/dashboard/track-giftcard">
                <img src="/assets/img/marketing.png" alt="" />
                <span>Track Gift Cards</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/dashboard/groups">
                <img src="/assets/img/profile-2user.png" alt="" />
                <span>Groups</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/dashboard/recent-activity">
                <img src="/assets/img/activity.png" alt="" />
                <span>Recent Activities</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/dashboard/receipts">
                <img src="/assets/img/archive-book.png" alt="" />
                <span>Receipts</span>
              </NavLink>
            </li> */}
            {/* <li><NavLink to="/dashboard/settings"><img src="/assets/img/setting-2.png" alt="" /><span>Settings</span></NavLink></li> */}
            <li>
              <button
                onClick={() => {
                  localStorage.clear();
                  navigate("/login");
                }}
                className="log-out-btn btn"
              >
                <img src="/assets/images/logout.png" alt="" />{" "}
                <span>Log Out</span>
              </button>
            </li>
            <br />
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
