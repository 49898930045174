import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import LoadingButton from "../../common/LoadingButton";
import LoadingPage from "../../common/LoadingPage";

const TableRow = ({ activeIndex, setActiveIndex, assignedCards, isloaded }) => {
  const [isSendingSms, setSendingSms] = useState(false);
  const [isSendingEmail,setSendingEmail]=useState(false);

  const handleSendSMS = (e, ele) => {
    e.preventDefault();
    Swal.fire({
      title: "Question",
      text: "Do you want to proceed with this action?",
      icon: "question",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#198754",
    }).then((results) => {
      if (results.isConfirmed) {
        setSendingSms(true);
        const message = `Good news!! ${ele?.employee?.fullname.toUpperCase()}, you have been gifted a card worth GHS ${
          ele?.card?.amount
        } from your company ${localStorage
          .getItem("organization")
          .toUpperCase()}.  Kindly find the card details for redemption. 
Card Code: ${ele?.puchase_code}
Merchant Info : ${ele?.card?.client?.name} (Tel: ${
          ele?.card?.client?.telephone
        })
        
To redeem your gift card, kindly click on this link to read more: https://primeegiftcard.com/prime-usage. To manage your gift cards, kindly download the mobile app and signup with 
this phone number by clicking on this link: https://qrs.ly/she35mp.
you can call +233548958517/+233207920300/+233247117448 or 
visit our website for more info: https://www.primeegiftcard.com`;

        axios
          .post("/support.switch/v1/support/mssn/send", {
            message: message,
            string_mssns: ele?.employee?.telephone,
          })
          .then((res) => {
            setSendingSms(false);
            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Sms has been sent successfully",
              confirmButtonColor: "#198754",
            });
          })
          .catch((_) => {
            setSendingSms(false);
            Swal.fire({
              title: "Failed",
              icon: "error",
              text: "SMS sending failed",
            });
          });
      }
    });
  };

  const handleSendEmail = (e, ele) => {
    e.preventDefault();
    
    Swal.fire({
      title: "Question",
      text: "Do you want to proceed with this action?",
      icon: "question",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#198754",
    }).then((results) => {
      if (results.isConfirmed) {
        setSendingEmail(true);

        axios
          .post("/support.switch/v1/support/emails/card-account-send", {
            code: ele?.puchase_code,
            organization_id: localStorage?.getItem('organization_id'),
          })
          .then((res) => {
            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Email has been sent successfully",
              confirmButtonColor: "#198754",
            });
          })
          .catch((_) => {
            Swal.fire({
              title: "Failed",
              icon: "error",
              text: "Email sending failed",
            });
          }).finally(()=>setSendingEmail(false));
      }
    });
  };


  return (
    <>
      {isloaded ? (
        assignedCards
          .filter((card) => card?.link_state === "LINKED")
          .map((ele, i) => {
            const isActive = i === activeIndex;

            return (
              <div key={i} className="row-one">
                <div className="d-flex justify-content-between table-content2">
                  <div className="div-size10">
                    <p>
                      {isActive ? (
                        <img
                          src="/assets/images/arrowup.png"
                          onClick={(i) => !setActiveIndex(i)}
                          className="cur-pointer"
                          alt="arrow"
                        />
                      ) : (
                        <img
                          src="/assets/images/arrowdown.png"
                          onClick={() => setActiveIndex(i)}
                          className="cur-pointer"
                          alt="arrow"
                        />
                      )}
                    </p>
                  </div>
                  <div className="div-size">
                    <div className="table-img-size">
                      <img src={ele?.card?.image} alt="card" />
                    </div>
                  </div>
                  <div className="div-size text-capitalize">
                    {ele?.employee?.fullname}
                  </div>
                  <div className="div-size text-center">
                    {ele?.employee?.telephone}
                  </div>
                  <div className="div-size text-center">
                    {ele?.card?.amount}
                  </div>
                  <div className="div-size d-flex justify-content-end mr-2">
                    {Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(Date.parse(ele?.purchased_at))}
                  </div>
                  <div className="div-size">
                    <span className="btn btn-sm mr-2">
                      {ele?.employee?.dob||'N/A'}
                    </span>
                  </div>
                  <div className="div-size">
                    {isSendingSms ? (
                      <LoadingButton />
                    ) : (
                      <button
                        className="btn btn-success mr-2 btn-sm"
                        onClick={(e) => handleSendSMS(e, ele)}
                      >
                        Resend SMS
                      </button>
                    )}
                    {isSendingEmail ? (
                      <LoadingButton />
                    ) : (
                      <button
                        className="btn btn-dark mr-2 mt-2 btn-sm"
                        onClick={(e) => handleSendEmail(e, ele)}
                      >
                        Resend Email
                      </button>
                    )}
                  </div>
                  {/* <div className="div-size">
                  <button type="button" className="btn btn-dark">
                    Manage card
                  </button>
                </div> */}
                </div>
                <hr />
                {isActive && (
                  <div className="d-block justify-content-between table-content1 table-content-color1">
                    <div className="ms-5">
                      <div className="d-flex table-content1 justify-content-between">
                        <div className="div-size1 d-block">
                          <h3 className="category-text">Merchant</h3>
                          <h4 className="text-capitalize">
                            {ele?.card?.client_name}
                          </h4>
                        </div>
                        <div className="div-size1">
                          <h3 className="category-text">Card Status</h3>
                          <h4>{ele?.link_state}</h4>
                        </div>
                        <div className="div-size1">
                          <h3 className="category-text">Employee Position</h3>
                          <h4>{ele.employee?.position}</h4>
                        </div>
                        <div className="div-size1">
                          <h3 className="category-text">Card Title</h3>
                          <h4>{ele?.card?.title}</h4>
                        </div>
                      </div>
                      <div className="d-flex table-content1 justify-content-between">
                        <div className="div-size1">
                          <h3 className="category-text">Card Description</h3>
                          <h4>{ele?.card?.description}</h4>
                        </div>
                        <div className="div-size1">
                          <h3 className="category-text">
                            Location of merhcant
                          </h3>
                          <h4>{ele?.card?.client_location}</h4>
                        </div>
                        {/* <div className="div-size1 d-block">
                        <h3>Date Assigned</h3>
                        <h4>1/03/2022</h4>
                      </div>
                      <div className="div-size1">
                        <h3>Time Assigned</h3>
                        <h4>11:39:15</h4>
                      </div> */}
                        <div className="div-size1"></div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })
      ) : (
        <div className="d-flex justify-content-center mt-5">
          <LoadingPage />
        </div>
      )}
    </>
  );
};
export default TableRow;
