import React from "react";

const Category = ({ image, title, bg = '',id='',setCategoryId }) => {
  return (
    <>
      <div className="d-flex flex-column justify-content-left align-items-left">
        <div className="">
          <div className={`shop-img-cover ${bg}`}>
            <a href={()=>{}} onClick={()=>setCategoryId(id)}>
              <img
                src={image}
                // width="100%"
                className="category-image-size test jus-content"
                alt="category"
              />
            </a>
          </div>
        </div>
        <div className="text-center category-text mt-2">{title}</div>
      </div>
    </>
  );
};

export default Category;
