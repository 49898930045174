import GiftCard from "./GiftCard";
import Slider from "react-slick";
import { useEffect,useState,React } from "react";
import axios from "axios";
import { Skeleton } from '@mui/material';

const TopBrands = () => {
  const [primeCards, setPrimeCards] = useState([]);

  const settings = {
    infinite: true,
    autoplay: true,
    variableWidth: true,
    speed: 600,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          // slidesToShow: 3,
          // slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          // slidesToShow: 2,
          // slidesToScroll: 2,
          variableWidth: true,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: false,
          dots: false
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  // featuredCards = cards.filter((item) => {
  //   return item.prime_only === true || item.is_promotion === true;
  // });
  useEffect(() => {
    axios
      .get("/prime.sika/v1/published-cards/list", {
        params: { is_feature: true }
      })
      .then((res) => {
        if (res.data.success === true && res.data.status === 200) {
          setPrimeCards(res.data.data.cards);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  return (
    <>
      <div className="row">
        <div className="shop-text-header mt-4">
          <h2>Gift Cards from Top Brands</h2>
        </div>

        <section className="slider variable">
          <Slider {...settings}>
            {primeCards.length > 0
              ? primeCards.map((item, index) => (
                  <div className="col-md-3 col-4" key={index}>
                    <GiftCard
                      id={item?.client_id}
                      image={item?.image}
                      title={item?.client_name}
                      client_location={item?.client_location}
                      amount={`${item?.client?.min_card_price} - ${item?.client?.max_card_price}`}
                    />
                  </div>
                ))
              : Array(5)
                  .fill("")
                  .map((_, index) => (
                    <div className=" mx-2 mt-3" key={index}>
                      <div className="card card-h-1 me-2">
                        <div className="card-img">
                          <Skeleton
                            variant="rectangular"
                            width="11vw"
                            height="7vw"
                          />
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "0.89rem" }}
                          />
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "0.89rem" }}
                            className="mt-2"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
          </Slider>
        </section>
      </div>
    </>
  );
};

export default TopBrands;
