import GiftCard from "./GiftCard";
import React from "react";

const CategoryCards = ({ cards, categoryId, searchTerm }) => {
  return (
    <>
      {categoryId !== "" ? (
        <>
          <div className="row">
            <div className="shop-text-header mt-4">
              <h2>Cards by category</h2>
            </div>
          </div>
          {/* {console.log(cards)} */}
          <div className="row">
            {cards.filter((value) => {
              if (Number(value.card_category_id) === Number(categoryId)) {
                return value;
              }
            }).length > 0 ? (
              cards
                .filter((value) => {
                  if (Number(value.card_category_id) === Number(categoryId)) {
                    return value;
                  }
                })
                .map((item, index) => {
                  return (
                    <div
                      className="col col-sm-6 col-md-2 col-lg-2  mt-2"
                      key={index}
                    >
                      <GiftCard
                        id={item.client_id}
                        image={item.image}
                        title={item.client_name}
                        client_location={item.client_location}
                        amount={`${item.client.min_card_price} - ${item.client.max_card_price}`}
                      />
                    </div>
                  );
                })
            ) : (
              <div className="row">
                <div className="subtitle text-left mt-4">
                  <h2>No Card is available for the selected category</h2>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default CategoryCards;
