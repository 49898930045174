import { useState,React } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import LoadingButton from "./../../common/LoadingButton";
import Swal from "sweetalert2";
const Credentials = ({
  handleUserInput,
  userInput,
  changeScreen,
  getCountry,
  nextScreen,
  setNextScreen,
  latitude,
  longitude
}) => {
  const [isProcessing, setProcessing] = useState(false);

  // useEffect(() => {
  //   // setProcessing(false)
  // }, [isProcessing]);

  // const [countries, setCountries] = useState([]);

  // useEffect(() => {
  //   axios
  //     .get("/market.place/v1/country/list")
  //     .then((res) => {
  //       if (res.data.success === true && res.data.status === 200) {
  //         setCountries(res.data.data.countries);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err.response.data);
  //     });
  // }, []);

  // const submitForm = (e) => {
  //   e.preventDefault();
  //   setProcessing(true);
  //   var for_business=(userInput.user_type==='individual')?false:true
  //   var data = {
  //     telephone:userInput.phone,
  //     // telephone: "0558571228",
  //     password: userInput.password,
  //     surname: userInput.last_name,
  //     first_name: userInput.first_name,
  //     currency: "GHS",
  //     email: userInput.email,
  //     gender: "male",
  //     profile_pic_url: "",
  //     country:{
  //       name: userInput.country_name,
  //       code_number: userInput.code_number,
  //       code_name: userInput.code_name,
  //     },
  //     country_code_name: userInput.code_name,
  //     for_business:for_business
  //   };
  //   console.log(data);
  //   axios
  //     .post("/prime.sika/v2/customers/signup", data)
  //     .then((res) => {
  //       if(res.data.status===201 && res.data.success===true){

  //         changeScreen(e)
  //       }
  //     })
  //     .catch((err) => {
  //       if(err.response.data.status===400 && err.response.data.success===false){
  //         console.log(err.response)
  //         window.alert(err.response.data.error_msg)
  //       }else{
  //         console.log(err.response)
  //       }
  //     });
  // };

  const [type, setType] = useState("password");
  const [type1, setType1] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const [icon1, setIcon1] = useState(eyeOff);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };
  const handleToggle1 = () => {
    if (type1 === "password") {
      setIcon1(eye);
      setType1("text");
    } else {
      setIcon1(eyeOff);
      setType1("password");
    }
  };

  const [errors, setError] = useState({
    password: "",
    password_confirmation: ""
  });

  const handleOnChange = (e) => {
    setError((error) => ({
      ...error,
      [e.target.name]: ""
    }));
    handleUserInput(e);
  };

  // const handleSubmit = () => {

  // };

  const submitForm = (e) => {
    setProcessing(true);
    var password = userInput.password;
    e.preventDefault();
    if (password.length < 8 || !password) {
      setError((error) => ({
        ...error,
        password: "Password length cannot be less than 8 characters"
      }));
      setProcessing(false);
    } else if (userInput.password !== userInput.password_confirmation) {
      setError((error) => ({
        ...error,
        password: "Passwords do not match"
      }));
      setProcessing(false);
    } else {
      const data = {
        password: userInput.password,
        verification_by: "telephone",
        telephone: userInput.phone,
        email: userInput.email,
        first_name: userInput.first_name,
        surname: userInput.surname,
        country_code_name: "GH",
        // name: userInput.name,
        // pin: userInput.pin,
        organization: {
          name: userInput.business_name,
          telephone: userInput.phone,
          email: userInput.email,
          telephones: [userInput.phone],
          location_cordinates: {
            latitude: latitude,
            longitude: longitude
          }
        }
      };

      axios
        .post("prime.sika/v1/plus/customers/signup", data)
        .then((res) => {
          console.log(res.data);
          console.log(res.data.success);
          console.log(res.data.status);
          if (res.data.success === true && res.data.status === 201) {
            changeScreen(e);
            Swal.fire({
              title: "Info",
              text: "An account verification code has been sent to your phone",
              icon: "info",
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: "Okay",
              confirmButtonColor: "#00b17d",
              denyButtonText: `No`
            });
          }
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.status === 400) {
            alert(err.response.data.error_msg);
            setNextScreen(0);
            // setRegistrationFailed(true);
          }
        });
    }
  };

  return (
    <div className="col-lg-6 col-12 pr-0 right-div">
      <div className="card card-size1 mt-5">
        <div className="card-body">
          <div className="row">
            <div className="d-flex justify-content-between">
              <button onClick={() => setNextScreen(1)}>
                <img src="/assets/images/arrow-left.png" alt="" />
              </button>
              <div className="d-flex float-end log-size">
                <h4>
                  Already registered?
                  <Link to="/login" className="btn btn-outline-dark">
                    Log In
                  </Link>
                </h4>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="mt-3">
                <h3>Sign up to Prime+</h3>
              </div>
              <div>
                <h4>Enter your details below</h4>
              </div>
              <div className="d-flex line-grp">
                <div className="line active"></div>
                <div className="line active"></div>
                <div className="line active"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
              <div className="font-enter">
                <h4>
                  Last step, <br />
                  Create your account password?
                </h4>
              </div>
              <form onSubmit={submitForm}>
                <div className="form-group mt-4">
                  <div className="eye-pos">
                    <input
                      type={type}
                      className="form-control textbx-size1"
                      placeholder="Enter your password"
                      value={userInput.password}
                      name="password"
                      onChange={handleOnChange}
                      required
                    />
                    <span onClick={handleToggle}>
                      <Icon
                        icon={icon}
                        size={18}
                        className="view-password-icon1"
                      />
                    </span>
                  </div>
                  <small className="text-danger">{errors.password}</small>
                </div>
                <div className="form-group mt-4">
                  <div className="eye-pos">
                    <input
                      type={type1}
                      className="form-control textbx-size1"
                      placeholder="Verify Password"
                      value={userInput.password_confirmation}
                      name="password_confirmation"
                      onChange={handleOnChange}
                      required
                    />
                    <span onClick={handleToggle1}>
                      <Icon
                        icon={icon1}
                        size={18}
                        className="view-password-icon1"
                      />
                    </span>
                  </div>
                </div>

                <div className="d-flex  mt-3 check-text-size">
                  <input
                    type="checkbox"
                    className="checkbox-design"
                    
                    name="special_offer"
                    value="single"
                    onChange={handleUserInput}
                  />
                  <label htmlFor="special_offer">
                    <h4 className="ms-2">
                      Yes, I'd like to be first to recieve news and special
                      offers from <span className="text-green">Prime+</span>
                    </h4>
                  </label>
                </div>
                <div className="d-flex  mt-2 check-text-size">
                  <input
                    type="checkbox"
                    className="checkbox-design"
                    name="terms"
                    value="yes"
                    onChange={handleUserInput}
                    required
                  />
                  <label htmlFor="franchise">
                    <h4 className="ms-2">
                      By continuing, you agree to Prime+'s{" "}
                      <span className="text-green">Privacy Policy</span> and{" "}
                      <span className="text-green">Terms of Service</span>
                    </h4>
                  </label>
                </div>
                <div className="mt-4 continue-btn">
                  {!isProcessing ? (
                    <button type="submit" className="btn btn-dark btn-next">
                      Continue
                    </button>
                  ) : (
                    <LoadingButton />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Credentials;
