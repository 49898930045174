import { useState, useEffect, React } from "react";
import axios from "axios";
import LoadingButton from "../../common/LoadingButton";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import useGetFetch from "../../common/customHooks/useGetFetch";
import LoadingPage from "../../common/LoadingPage";
import Pagination from "../../common/helpers/Pagination";

const ReceipientUpload = ({
  setScreen,
  cart,
  clearSelectedOptions,
  userInput,
  useDob,
}) => {
  const [page,setPage]=useState(1);
  const [no_of_pages,setNoPages]=useState(1);
  const { data: groups } = useGetFetch(`/prime.sika/v1/plus/groups/list`,page);
  const [reloadTable, setReloadTable] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [positions, setPositions] = useState([]);
  const navigate = useNavigate();
  const [apiCart, setApiCart] = useState({
    items: [],
    buy_for_later: false,
  });
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isloaded, setLoaded] = useState(false);

  const handleGroupSelected = (e) => {
    setSearch("");
    setCategory("");
    setSelectedGroup(e.target.value);
  };

  const fileteredEmployee = employees.filter((item) => {
    if (search.toLowerCase() !== "") {
      return item?.fullname?.toLowerCase()?.includes(search);
    } else if (category !== "") {
      return item?.position?.toLowerCase().includes(category);
    } else if (selectedGroup !== null) {
      for (let i = 0; i < item?.groups?.length; i++) {
        if (item?.groups?.[i]?.id === Number(selectedGroup)) {
          return item;
        }
      }
    } else {
      return item;
    }
  });

  useEffect(() => {
    axios
      .get("/prime.sika/v1/plus/employees/categories")
      .then((res) => {
        if (res.data.success === true && res.data.status === 200) {
          setPositions(res.data.data.categories);
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (
          err.response.data.status === 401 &&
          err.response.data.success === false &&
          err.response.data.error_msg === "Unauthorized"
        ) {
          localStorage.removeItem("token");
          navigate("/login");
        }
      });
  }, [navigate]);

  const handleChange = (e) => {
   
    const { name, checked } = e.target;
    if (name === "allselect") {
      let tempUser = fileteredEmployee.map((ele) => {
        return { ...ele, isChecked: checked };
      });
      setEmployees(tempUser);
      checked ? setSelectedEmployees(tempUser) : setSelectedEmployees([]);
    } else {
      let tempUser = employees.map((ele) =>
        ele.id === Number(name) ? { ...ele, isChecked: checked } : ele
      );
      setEmployees(tempUser);
      var results = selectedEmployees;
      if (checked) {
        results.push(Number(name));
        setSelectedEmployees(results);
      } else {
        results = results.filter((value) => Number(value) !== Number(name));
        setSelectedEmployees(results);
      }
    }
   
  };

  useEffect(() => {
    var results = fileteredEmployee.map((item) => {
      return item.id;
    });

    setFilteredList(results);
  }, [search, category, employees]);

  useEffect(() => {
    axios
      .get(
        `/prime.sika/v1/plus/employees/${localStorage.getItem(
          "organization_code"
        )}/list?page=${page}`
      )
      .then((res) => {
        setLoaded(true);
        if (res.data.status === 200 && res.data.success === true) {
          setEmployees(res.data.data.employees);
          setNoPages(res?.data?.data?.total_pages)
        }
      })
      .catch((err) => {
        setLoaded(true);
        Swal.fire("Error", "Error Loading Employees", "error");
        console.log(err.response);
      });
  }, [reloadTable,page]);

  const handleCartProcessing = () => {
    setProcessing(true);
    if (selectedEmployees.length === 0) {
      alert("Please select at least one employee to proceed");
      setProcessing(false);
    } else {
      var items = [];
      for (let i = 0; i < selectedEmployees.length; i++) {
        var card_id, quantity, employee_id;
        employee_id = selectedEmployees[i]?.id || selectedEmployees[i];
        for (let i = 0; i < cart.length; i++) {
          card_id = cart[i].card_id;
          quantity = cart[i].quantity;
          items.push({
            quantity: Number(quantity),
            card_id: card_id,
            employee_id: employee_id,
          });
        }
      }
      setApiCart((apiCart) => ({
        ...apiCart,
        items,
      }));
    }
  };

  const date = new Date();

  useEffect(() => {
    var scheduled_date =
      userInput.scheduled_date === ""
        ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        : userInput.scheduled_date;

    if (selectedEmployees.length > 0) {
      axios
        .post("/prime.sika/v1/plus/business_customer/carts/create", {
          items: apiCart.items,
          buy_for_later: apiCart.buy_for_later,
          // apiCart
          use_birthday_schedule: useDob,
          scheduled_date: scheduled_date,
          message: userInput.message,
        })
        .then((res) => {
          setProcessing(false);
          setScreen("orderPreviewWithEmployees");
        })
        .catch((err) => {
          console.log(err.response);
          setProcessing(false);
          if (err?.response?.data?.status === 400) {
            const message = err?.response?.data?.error_msg;
            Swal.fire({
              title: "Failed",
              text: message,
              icon: "error",
              confirmButtonColor: "#de0a26",
            });
          } else {
            Swal.fire({
              title: "Failed",
              text: "Error adding items to cart",
              icon: "error",
              confirmButtonColor: "#de0a26",
            });
          }
        });
    }
  }, [apiCart]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 ">
          <img
            src="/assets/images/arrow-left.png"
            alt=""
            className="img-fluid cur-pointer"
            onClick={() => {
              clearSelectedOptions();
              setScreen("card-details");
            }}
          />
        </div>
        <div className="row">
          <div className="d-flex justify-content-between regist-font mt-1">
            <h4 className="my-auto subtitle">Select Employees</h4>
            <h4 className="my-auto P-me subtitle">
              {selectedEmployees.length} of {employees?.length} Employees
              Selected
            </h4>
            {selectedEmployees.length > 0 && (
              <>
                {!processing ? (
                  <button
                    className="btn btn-success"
                    onClick={handleCartProcessing}
                  >
                    Proceed
                  </button>
                ) : (
                  <div className="w-25">
                    <LoadingButton />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-12">
          <div className="card-table card-table-height1">
            <div className="card-header">
              <div className="d-block">
                <div className="emp-font">
                  <h4>Select an employee by clicking on the checkbox</h4>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-md-4 p-2">
                    <div className="form-group">
                      <select
                        className="form-control w-100"
                        onChange={(e) => {
                          setSearch("");
                          setCategory(e.target.value);
                        }}
                      >
                        <option value="">--- Filter By Positions ---</option>
                        {positions.map((position, index) => (
                          <option
                            key={index}
                            value={position}
                            className="text-capitalize"
                          >
                            <div className="text-capitalize">
                              {position.toUpperCase()}
                            </div>
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4 p-2">
                    <div className="form-group">
                      <select
                        className="form-control w-100"
                        onChange={handleGroupSelected}
                      >
                        <option value="">--- Filter By Groups ---</option>
                        {groups?.groups
                          ?.filter((group) => {
                            if (group?.suspended === false) {
                              return group;
                            }
                          })
                          ?.map((group, index) => (
                            <option
                              key={index}
                              value={group?.id}
                              className="text-capitalize"
                            >
                              <div className="text-capitalize">
                                {group?.name?.toUpperCase()}
                              </div>
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4 p-2">
                    <input
                      type="text"
                      placeholder="Search Employee"
                      onChange={(e) => {
                        setCategory("");
                        setSearch(e.target.value);
                      }}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body booking_card">
              <div className="table-responsive">
                {isloaded ? (
                  <table className="datatable table table-stripped table table-hover table-center mb-0">
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            className="checkbox-design"
                            onChange={handleChange}
                            name="allselect"
                            checked={
                              fileteredEmployee.filter(
                                (ele) => ele?.isChecked !== true
                              ).length < 1
                            }
                          />
                        </th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Telephone</th>
                        <th>Position</th>
                      </tr>
                    </thead>
                    <tbody className="mb-2">
                      {fileteredEmployee.map((employee, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <input
                                type="checkbox"
                                className="checkbox-design"
                                name={employee.id}
                                checked={employee?.isChecked || false}
                                onChange={handleChange}
                              />
                            </td>
                            <td>{employee?.fullname ?? "N/A"}</td>
                            <td>{employee?.email ?? "N/A"}</td>
                            <td>{employee?.telephone}</td>
                            <td className="text-capitalize">
                              {employee?.position}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <Pagination
            pageCount={no_of_pages}
            handlePageClick={(e) => setPage(e.selected + 1)}
          />
                  </table>
                ) : (
                  <div className="flex text-center justify-content-center align-items-center">
                    <LoadingPage />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5 mb-5">
        <div className="col-md-6">
          <div>
            <h4>Merchant Info</h4>
          </div>
          <div className="mt-3">
            <h3 className="text-faint text-left text-faint-height">
              Sleek and sophisticated, The Oceanaire Seafood Room provides the
              <span>
                perfect setting to enjoy the freshest seafood flown in daily
                from
              </span>
              around the world. Our knowledgeable staff will delight you with
              <span>
                exceptional service – guiding you through the wide variety of
                items on{" "}
              </span>
              our menu and helping you select the perfect wine to complement
              <span>
                your meal. You'll find out why The Oceanaire has been voted one
                of
              </span>
              the best seafood restaurants in the country! www.theoceanaire.com
            </h3>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex justify-content-between mx-5">
            <div className="ms-5">
              <div>
                <h4>Location</h4>
              </div>
              <div className="mt-3">
                <h3 className="text-faint text-left text-faint-height">
                  East Legon <span>Accra Central</span> Achimota
                </h3>
              </div>
            </div>
            <div className="me-5">
              <div>
                <h4>Contact Info</h4>
              </div>
              <div className="mt-3">
                <h3 className="text-faint text-left text-faint-height">
                  0302113456 <span>024773456</span>
                </h3>
              </div>
            </div>
          </div>
          <div className="mt-5 mx-5">
            <a href="/card-details" className="text-success ms-5 retailer-a">
              Retailer Gift Cards Terms & Conditions
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceipientUpload;

