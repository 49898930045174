import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import LoadingButton from "../../common/LoadingButton";
import { Skeleton } from "@mui/material";
import Swal from "sweetalert2";
import PaymentOptions from "../PaymentOptions";

const OrderPreview = ({ userInput, card, setScreen, clearSelectedOptions,screen }) => {
  const [cart, setCart] = useState();
  // const [isCardsLoaded, setIsCardsLoaded] = useState(false);

  useEffect(() => {
    axios
      .get("/prime.sika/v1/carts/customer/current-cart")
      .then((res) => {
        if (res.data.success === true && res.data.status === 200) {
          setCart(res.data.data.cart);
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (
          err.response.data.status === 401 &&
          err.response.data.success === false &&
          err.response.data.error_msg === "Unauthorized"
        ) {
          localStorage.removeItem("token");
          axios
            .get("/prime.sika/v1/plus/business_customer/carts//current-cart")
            .then((res) => {
              if (res.data.success === true && res.data.status === 200) {
                setCart(res.data.data.cart);
              }
            });
        }
      });
  }, [screen]);

  const removeItem = (card_id) => {
    let data = {
      cart_id: cart?.cart_id,
    };

    axios
      .put(
        `/prime.sika/v1/carts/customer/remove/${card_id}?cart_id=${cart?.cart_id}`,
        data
      )
      .then((res) => {
        if (res.data.success === true && res.data.status === 200) {
          setCart(res.data.data.cart);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const clearCart = () => {
    axios
      .post(`/prime.sika/v1/carts/customer/clear?cart_id=${cart?.cart_id}`)
      .then((res) => {
        if (res.data.success === true && res.data.status === 201) {
          setCart(res.data?.data?.cart);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  const [paymentOption, setPaymentOption] = useState("standard");
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const navigate = useNavigate();

  const makePayment = () => {
    Swal.fire({
      title: "Confirmation",
      text: "Do you want to proceed with payment?",
      icon: "question",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
      confirmButtonColor: "#00b17d",
      denyButtonText: `No`,
      denyButtonColor: "#000",
    }).then((result) => {
      if (result.isConfirmed) {
        setPaymentProcessing(true);
        var data;
        localStorage.getItem('message')
          ? (data = {
              use_prime_wallet: false,
              cart_id: cart?.cart_id,
              payment_to: "ORGANIZATION",
              message: localStorage.getItem('message'),
              organization_code: localStorage.getItem("organization_code"),
              return_url: "https://prime-plus.20pesewas.com/dashboard",
              payment_option: paymentOption,
            })
          : (data = {
              use_prime_wallet: false,
              cart_id: cart?.cart_id,
              payment_to: "ORGANIZATION",
              organization_code: localStorage.getItem("organization_code"),
              return_url: "https://prime-plus.20pesewas.com/dashboard",
              payment_option: paymentOption,
            });

        axios
          .post("/prime.sika/v1/plus/payments/customer", data)
          .then((res) => {
            if (res.data.status === 201 && res.data.success === true) {
              if (res.data.data.provider === "direct") {
                localStorage.setItem("reference", res?.data?.data?.reference);
                navigate("/dashboard/invoice");
              } else {
                window.location.replace(res.data.data.payment_url);
              }
              // window.open(res.data.data.payment_url, '_blank', 'noopener,noreferrer');
            }
          })
          .catch((err) => {
            setPaymentProcessing(false);
            console.log(err.response);
          });
      }
    });
  };

  var rows = cart?.cards?.map((item, index) => (
    <tr key={index}>
      <td width="30%">
        <img
          src={item.image}
          alt="Card"
          width="70%"
          style={{ borderRadius: "10px" }}
        />
      </td>
      <td>{item.title}</td>
      <td>¢ {item.amount}</td>
      <td>
        <input
          className="btn btn-danger"
          type="button"
          onClick={(e) => {
            e.target.value = "Please wait...";
            removeItem(item.item_id);
          }}
          value="Remove"
        />
      </td>
    </tr>
  ));

  return (
    <div className="container">
      <div className="col-lg-12 mb-4">
        <img
          src="/assets/images/arrow-left.png"
          alt=""
          className="img-fluid cur-pointer"
          onClick={() => {
            clearSelectedOptions();
            setScreen("card-details");
          }}
        />
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="row">
            <div className="col-md-11">
              {cart?.cards?.[0]?.image ? (
                <img
                  src={cart?.cards[0]?.image}
                  alt="Product"
                  width="100%"
                  style={{ borderRadius: "10px" }}
                />
              ) : (
                <Skeleton
                  variant="rectangular"
                  width={350}
                  height={230}
                  style={{ borderRadius: "10px" }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-md-8 mt-2">
          <div className="container">
            <div className="d-flex flex-row justify-content-between">
              <div className="p-0">
                <h3 className="product-detail-title">
                  {cart?.cards ? (
                    cart?.cards[0]?.client_name
                  ) : (
                    <Skeleton variant="rectangular" width={300} height={25} />
                  )}
                </h3>
              </div>
              <div className="p-2">
                <div className="total-container">
                  <div className="shop-card-text-desc">
                    Total:{" "}
                    <span className="total-amount">¢ {cart?.total_amount}</span>
                  </div>
                </div>
              </div>
            </div>

            {cart ? (
              cart?.cards?.length > 0 ? (
                <div className="mt-3">
                  <h3 className="text-faint text-left">
                    Cards Total:{" "}
                    <span className="text-success">¢ {cart?.net_amount}</span>
                  </h3>
                  <h3 className="text-faint text-left">
                    Prime Fees:{" "}
                    <span className="text-success">¢ {cart?.fees}</span>
                  </h3>
                </div>
              ) : (
                <h4 className="product-detail-title">Your Cart is Empty</h4>
              )
            ) : (
              <h4 className="product-detail-title">
                <Skeleton variant="rectangular" width={300} height={25} />
              </h4>
            )}

            <div className="row">
              <div className="mt-5">
                <div className="d-flex justify-content-start">
                  {cart?.cards?.length > 0 ? (
                    <>
                      {!paymentProcessing ? (
                        <div className="d-flex flex-column flex-column-reverse">
                          <div className="row">
                            <div className="p-0">
                              <button
                                className="btn btn-dark px-5 py-2 me-2 w-100 mx-3"
                                type="button"
                                onClick={makePayment}
                              >
                                Make Payment
                              </button>
                            </div>
                            {/* <div></div> */}
                          </div>
                          <div className="">
                            <PaymentOptions
                              setPaymentOption={setPaymentOption}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="w-25">
                          <LoadingButton />
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="p-0">
                      <Link
                        to="/dashboard/shop"
                        className="btn btn-success px-5 py-2 me-2"
                      >
                        Shop Gift Cards
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-12">
          <div className="d-flex flex-row justify-content-between">
            <div className="mb-3">
              <h3 className="my-auto">Order Preview</h3>
            </div>
            <div className="mb-3 d-flex">
              <div className="p-0">
                <Link
                  to="/dashboard/shop"
                  className="btn btn-success px-5 py-2 me-2"
                >
                  Shop More Cards
                </Link>
              </div>
              <button
                className="btn btn-danger px-5 py-2 me-2"
                onClick={clearCart}
              >
                Clear
              </button>
            </div>
          </div>
          <div className="card-table">
            <div className="card-body booking_card">
              <div className="table-responsive">
                <table className="datatable table table-stripped table table-hover table-center mb-0">
                  <thead>
                    <tr>
                      <th>Card</th>
                      <th>Title</th>
                      <th>Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5 mb-5">
        <div className="col-md-4">
          <div>
            <h4>Merchant Info</h4>
          </div>
          <div className="mt-3">
            <h3 className="text-faint text-left text-faint-height">
              {card?.description}
            </h3>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex justify-content-between mx-5">
            <div className="ms-5">
              <div>
                <h4>Location</h4>
              </div>
              <div className="mt-3">
                <h3 className="text-faint text-left text-faint-height">
                  {card?.client?.location}
                </h3>
              </div>
            </div>
            <div className="me-5">
              <div>
                <h4>Contact Info</h4>
              </div>
              <div className="mt-3">
                <h3 className="text-faint text-left text-faint-height">
                  {card?.client_telephone}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderPreview;
