import { useRef } from "react";
import { useState, React } from "react";

const CardValue = ({ amount, handleSelected, appendAmount, popAmount,id }) => {
  const styleRef = useRef(null);
  const [selected, setSelected] = useState(false);

  const handleClick = (e) => {
    handleSelected(e.target);
    if (selected === false) {
      appendAmount(e.target.value,id);
      setSelected(!selected);
      // console.log(styleRef.current);
    } else {
      popAmount(e.target.value,id);
      setSelected(!selected);
      // console.log(styleRef.current);
    }
  };

  var renderButton = "";
  if (selected === false) {
    renderButton = (
      <div className="col-sm-3 col-md-3 col-lg-3 mt-2">
        {/* <div className="form-group"> */}
          <button
            type="button"
            value={amount}
            ref={styleRef}
            className="form-control btn-outline-success"
            onClick={handleClick}
          >
            {amount}
          </button>
        {/* </div> */}
      </div>
    );
  }else{
    renderButton=<div className="col-sm-3 col-md-3 col-lg-3 mt-2">
        {/* <div className="form-group"> */}
          <button
            type="button"
            value={amount}
            ref={styleRef}
            className="form-control btn-outline-success btn-success text-white"
            onClick={handleClick}
          >
            {amount}
          </button>
        {/* </div> */}
      </div>
  }

  return renderButton;
};

export default CardValue;
