const PaymentOptions = ({ setPaymentOption }) => {
  return (
    <div className="d-flex">
      <div className="flex-column">
        <div className="d-flex p-2">
          <label
            htmlFor="standard"
            className="radio1 d-flex align-items-center"
          >
            <input
              type="radio"
              name="radio-phone"
              id="standard"
              className="radio__input mr-1"
              value="standard"
              onChange={() => setPaymentOption("standard")}
              defaultChecked
            />
            <div className="radio__radio1"></div>
            <h4 className="my-auto pur-text">Standard(online) Payment</h4>
          </label>
        </div>
        <p className="text-left text-left1 shop-card-text-desc loc-color amnt-pad br-span px-3 pt-2">
          Make payment instantly using momo{" "}
          <span>or bank cards via our integrated </span>
          <span> payment system</span>
        </p>
      </div>

      <div className="flex-column">
        <div className="d-flex p-2">
          <label
            htmlFor="purchase_for_later_use"
            className="radio1 d-flex align-items-center"
          >
            <input
              type="radio"
              name="radio-phone"
              id="purchase_for_later_use"
              className="radio__input mr-1"
              value="direct"
              onChange={() => setPaymentOption("direct")}
            />
            <div className="radio__radio1"></div>
            <h4 className="my-auto pur-text">Direct(Manual) Payment</h4>
          </label>
        </div>
        <p className="text-left text-left1 shop-card-text-desc loc-color amnt-pad br-span px-2 pt-2">
          Generate invoice and make payment{" "}
          <span>directly to our Bank Account </span>
          <span>or an alternate payment channel</span>
        </p>
      </div>
    </div>
  );
};

export default PaymentOptions;
