const EditOrder = () =>{
    return(
        <div
      className="modal fade"
      id="edit_order"
      aria-hidden="true"
      role="dialogs"
      tabindex="-1"
      aria-labelledby="edit_order"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body ">
            <div className="">
              <h3 className="contact-text">Update Order Details</h3>
            </div>
            <hr />
            <form action="">
              <div class="mt-3 mb-3">
                <label for="fullname" class=" footer-text">
                  Name <sup className="text-success">*</sup>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="name"
                />
              </div>
              <div class="mb-3">
                <label for="fullname" class=" footer-text">
                  Phone Number<sup className="text-success">*</sup>
                </label>
                <input
                  type="text"
                  class="form-control"     
                />
              </div>
              
              <div class="mb-3">
                <label for="dob" class="footer-text">
                  Scheduled Date
                </label>
                <input
                  type="date"
                  class="form-control"
                  name="dob"
                
                />
              </div>
              <div class="arr-dwn mb-3">
                <label for="category" class="footer-text">
                  Amount
                </label>
                <select
                  class="form-control"
                  name="employee_position"
               
                >
                 <option value="" className="text-capitalize"></option>
                  <option value="executive">Executive</option>
                  <option value="manager">Manager</option>
                  <option value="staff">Staff</option>
                </select>
                <img
                  src="assets/images/arrowdown.png"
                  alt=""
                  className="arr-dwn-pos"
                />
              </div>
              {/* <div class="mb-3">
                <label for="email" class="footer-text">
                  Employee Code
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="employee_code"
                  onChange={handleUserInput}
                  value={userInput.employee_code}
                  placeholder="Employee's code"
                />
              </div> */}

              <button
                type="submit"
                className="btn btn-outline-success form-control"
                data-bs-dismiss="modal" aria-label="Close"
              >
                <span class="btn-label">
                  <i class="fa fa-plus" />
                </span>{" "}
                &nbsp; Save and Close
              </button>
            </form>
            {/* <div className="card-body">
              </div> */}
          </div>
        </div>
      </div>
    </div>
    )
}
export default EditOrder;