import { Link } from "react-router-dom";
import { Skeleton } from '@mui/material';
import React from "react";

const GiftCard = ({id,image,title,client_location,amount}) => {
  

  return (
    <Link to={`/dashboard/shop/${id}`} className="no-decoration">
      <div className="card card-h-1 me-2">
        <div className="card-img">
          <div style={{Width:'12vw',Height:'7vw',objectFit:"cover",background:"#EAEAEA",borderRadius:"10px" }}>
            {
              image?
              <img
                src={image}
                width="100%"
                style={{ objectFit:"cover" }}
                alt="gift-card"
                className="card-image-size"
              />:
              <Skeleton variant="rectangular" width={210} height={118} className='skeleton-img'/>
            }
          </div>
          <div className="text-left shop-card-text1 mt-2">
            <h4 style={{ textTransform:'capitalize' }}>{title}</h4>
          </div>

          <p style={{ textTransform:'capitalize' }} className="text-left text-left1 shop-card-text-desc loc-color amnt-pad">
            {client_location?.toLowerCase()}
          </p>

          <p className="text-left  shop-card-text-price amnt-pad">
            <h4>GHS ¢{amount}</h4>
          </p>
        </div>
      </div>
    </Link>
  );
};

export default GiftCard;
