import NewMap from "../../dashboard/components/NewMap";
import { useState,React } from "react";

const PersonalDetails = ({
  userInput,
  handleUserInput,
  changeScreen,
  getMapLocation,
  location
}) => {
  const [errors, setErrors] = useState({
    business_name: "",
    business_location: ""
  });

  const clearError = (e) => {
    setErrors((errors) => ({
      ...errors,
      [e.target.name]: ""
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userInput.business_name === "") {
      setErrors((errors) => ({
        ...errors,
        business_name: "Please Enter Your Business Name"
      }));
    } else if (location?.address === "") {
      setErrors((errors) => ({
        ...errors,
        business_location: "Please Select Business Location"
      }));
    } else {
      changeScreen(e);
    }
  };
  // console.log(errors)
  return (
    <div className="col-lg-12">
      <div>
        <h3>Sign up to Prime+</h3>
      </div>
      <div>
        <h4>Enter your details below</h4>
      </div>
      <div className="d-flex line-grp">
        <div className="line active"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      <div className="font-enter">
        <h4>
          Hello, <span>What's your business name?</span>
        </h4>
      </div>

      <form onSubmit={handleSubmit}>
        <>
          <div className="form-group mt-4">
            <input
              type="text"
              className="textbx-size1"
              placeholder="Enter your business name here"
              value={userInput.business_name}
              name="business_name"
              onChange={(e) => {
                handleUserInput(e);
              }}
              required
            />
            <div className="text-danger">{errors.business_name}</div>
          </div>
          {/* Map Modal */}
          <div
            className="modal fade pt-5"
            id="location_modal"
            aria-hidden="true"
            role="dialogs"
            tabindex="-1"
            aria-labelledby="location_modal"
          >
            <div className="modal-dialog " role="document">
              <div className="modal-content moa">
                <div className="modal-body modal-padding">
                  <NewMap getMapLocation={getMapLocation} />
                </div>
              </div>
            </div>
          </div>
          {/* End of Map Modal */}
          <div className="form-group mt-4">
            <a data-bs-toggle="modal" href="#location_modal" className="loc-cont">
              <input
                type="text"
                className="textbx-size1"
                placeholder="Select business location"
                value={location?.address}
                name="business_location"
                onChange={(e) => {
                  handleUserInput(e);
                }}
                required
                readOnly
              />
              <img src="/assets/images/location.png" alt="" className="img-fluid loc-padding"/>
            </a>
            <div className="text-danger">{errors.business_location}</div>
          </div>
          <div className="form-group mt-4">
            <input
              type="email"
              className="textbx-size1"
              placeholder="Enter your business email here"
              value={userInput.email}
              name="email"
              onChange={(e) => {
                handleUserInput(e);
              }}
              required
            />
            <div className="text-danger">{errors.email}</div>
          </div>
        </>

        <div className="mt-4 continue-btn">
          <button
            type="submit"
            className="btn btn-dark btn-next"
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};

export default PersonalDetails;
