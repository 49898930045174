import React from "react";

const BusinessProfileTab = () => {
    return (
      <div className="col-lg-3">
        <div className="card card-chart2 z-position card-h10">
          <div className="px-lg-2 px-sm-3 pt-4">
            <h3>Actions</h3>
          </div>
          <ul className="nav d-block sidetab-font" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className="btn btn-outline-success tab-btn px-lg-2 px-sm-3 active"
                id="pills-profile-tab1"
                data-toggle="pill"
                href="#pills-profile1"
                role="tab"
                aria-controls="pills-profile1"
                aria-selected="false"
              >
                Business Information
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  };
  
  export default BusinessProfileTab;
  