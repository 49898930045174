import { useState, useEffect, React } from "react";
import { Skeleton } from "@mui/material";
import EditOrder from "../../dashboard2/components/EditOrder";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import LoadingButton from "../../common/LoadingButton";
import Swal from "sweetalert2";
import PaymentOptions from "../PaymentOptions";

const OrderPreviewWithEmployees = ({  card, setScreen,screen }) => {

  const navigate = useNavigate();
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [cartItemDeleting, setCartItemDeleting] = useState(false);
  const [cartCleared, setCardCleared] = useState(false);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("");
  const [positions, setPositions] = useState([]);

  const [paymentOption, setPaymentOption] = useState("standard");
  const [cart, setCart] = useState();
  useEffect(() => {
    axios
      .get("/prime.sika/v1/plus/business_customer/carts/list")
      .then((res) => {
        if (res.data.success === true && res.data.status === 200) {
          setCart(res?.data?.data?.carts?.[0]);
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (
          err.response.data.status === 401 &&
          err.response.data.success === false &&
          err.response.data.error_msg === "Unauthorized"
        ) {
          localStorage.removeItem("token");
          navigate("/login");
        }
      });

    axios.get("/prime.sika/v1/plus/employees/categories").then((res) => {
      if (res.data.success === true && res.data.status === 200) {
        setPositions(res.data.data.categories);
      }
    });
  }, [screen]);

  const [clearing, setClearing] = useState(false);
  const clearCart = () => {
    setClearing(true);
    axios
      .post("/prime.sika/v1/plus/business_customer/carts/clear", {
        cart_id: cart.cart_id,
      })
      .then((res) => {
        setCart(res.data.data.cart);
        setClearing(false);
        setCardCleared(true);
      });
  };

  const removeItem = (card_id) => {
    Swal.fire({
      title: "Confirmation",
      text: "Are your to delete this item",
      icon: "question",
      confirmButtonColor: "#198754",
      confirmButtonText: "Yes",
      showDenyButton: true,
      denyButtonText: "No",
    }).then((res) => {
      if (res.isConfirmed) {
        setCartItemDeleting(true);
        let data = {
          cart_id: cart?.cart_id,
        };

        axios
          .put(
            `/prime.sika/v1/plus/business_customer/carts/remove/${card_id}`,
            data
          )
          .then((res) => {
            if (res.data.success === true && res.data.status === 200) {
              setCart(res.data.data.cart);
            }
            setCartItemDeleting(false);
          })
          .catch((err) => {
            console.log(err.response);
            setCartItemDeleting(false);
          });
      }
    });
  };
  // console.log(cart)
  const makePayment = () => {
    Swal.fire({
      title: "Confirmation",
      text: "Do you want to proceed with payment?",
      icon: "question",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
      confirmButtonColor: "#00b17d",
      denyButtonText: `No`,
      denyButtonColor: "#000",
    }).then((result) => {
      if (result.isConfirmed) {
        setPaymentProcessing(true);
        var data;
        localStorage?.getItem('message')
          ? (data = {
              use_prime_wallet: false,
              cart_id: cart?.cart_id,
              payment_to: "ORGANIZATION",
              message: localStorage.getItem('message'),
              return_url: "https://prime-plus.20pesewas.com/dashboard",
              payment_option: paymentOption,
            })
          : (data = {
              use_prime_wallet: false,
              cart_id: cart?.cart_id,
              payment_to: "ORGANIZATION",
              return_url: "https://prime-plus.20pesewas.com/dashboard",
              payment_option: paymentOption,
            });

        axios
          .post("/prime.sika/v1/plus/payments/business_customer", data)
          .then((res) => {
            if (res.data.status === 201 && res.data.success === true) {
              if (res.data.status === 201 && res.data.success === true) {
                if (res.data.data.provider === "direct") {
                  localStorage.setItem("reference", res?.data?.data?.reference);
                  navigate("/dashboard/invoice");
                } else {
                  window.location.replace(res.data.data.payment_url);
                }
              }
            }
          })
          .catch((err) => {
            setPaymentProcessing(false);
            console.log(err.response);
          });
      }
    });
  };

  return (
    <div className="container">
      <div className="col-lg-12 mb-4">
        {cartCleared && (
          <img
            src="/assets/images/arrow-left.png"
            alt=""
            className="img-fluid cur-pointer"
            onClick={() => {
              setScreen("receipients");
            }}
          />
        )}
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="row">
            <div className="col-md-11">
              {cart?.cards?.[0]?.image ? (
                <img
                  src={cart?.cards[0]?.image}
                  alt="Product"
                  width="100%"
                  style={{ borderRadius: "10px" }}
                />
              ) : (
                <Skeleton
                  variant="rectangular"
                  width={350}
                  height={230}
                  style={{ borderRadius: "10px" }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-md-8 mt-2">
          <div className="container">
            <div className="d-flex flex-row justify-content-between">
              <div className="p-0">
                <h3 className="product-detail-title">
                  {cart?.cards ? (
                    cart?.cards[0]?.client_name
                  ) : (
                    <Skeleton variant="rectangular" width={300} height={25} />
                  )}
                </h3>
              </div>
              <div className="p-2">
                <div className="total-container">
                  <div className="shop-card-text-desc">
                    Total:{" "}
                    <span className="total-amount">¢ {cart?.total_amount}</span>
                  </div>
                </div>
              </div>
            </div>

            {cart ? (
              cart?.cards?.length > 0 ? (
                <div className="mt-3">
                  <h3 className="text-faint text-left">
                    Cards Total:{" "}
                    <span className="text-success">¢ {cart?.net_amount}</span>
                  </h3>
                  <h3 className="text-faint text-left">
                    Prime Fees:{" "}
                    <span className="text-success">¢ {cart?.fees}</span>
                  </h3>
                </div>
              ) : (
                <h4 className="product-detail-title">Your Cart is Empty</h4>
              )
            ) : (
              <h4 className="product-detail-title">
                <Skeleton variant="rectangular" width={300} height={25} />
              </h4>
            )}

            <div className="row">
              <div className="mt-5">
                <div className="d-flex justify-content-start">
                  {cart?.cards?.length > 0 ? (
                    <>
                      {!paymentProcessing ? (
                        <div className="d-flex flex-column flex-column-reverse">
                          <div className="row">
                            <div className="p-0">
                              <button
                                className="btn btn-dark px-5 py-2 me-2 w-100 mx-3"
                                type="button"
                                onClick={makePayment}
                              >
                                Make Payment
                              </button>
                            </div>
                            {/* <div></div> */}
                          </div>
                          <div className="">
                            <PaymentOptions
                              setPaymentOption={setPaymentOption}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="w-25">
                          <LoadingButton />
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="p-0">
                      <Link
                        to="/dashboard/shop"
                        className="btn btn-success px-5 py-2 me-2"
                      >
                        Shop Gift Cards
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-12">
          <div className="d-flex flex-row justify-content-between">
            <div className="mb-3">
              <h3 className="my-auto">Order Preview</h3>
            </div>
            <div className="mb-3">
              <div className="d-flex">
                <div className="p-0">
                  <Link
                    to="/dashboard/shop"
                    className="btn btn-success px-5 py-2 me-2"
                  >
                    Shop More Cards
                  </Link>
                </div>
                {!clearing ? (
                  <button
                    className="btn btn-danger px-5 py-2 me-2"
                    onClick={clearCart}
                  >
                    Clear All
                  </button>
                ) : (
                  <button className="btn btn-danger px-5 py-2 me-2" disabled>
                    Please wait...
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="card-table">
            <div className="card-body booking_card">
              <div className="row mb-3">
                <div className="col-md-4">
                  <div className="form-group">
                    <select
                      className="form-control w-100"
                      onChange={(e) => {
                        setSearch("");
                        setCategory(e.target.value);
                      }}
                    >
                      <option value="">--- Filter By Positions ---</option>
                      {positions.map((position, index) => (
                        <option
                          key={index}
                          value={position}
                          className="text-capitalize"
                        >
                          <div className="text-capitalize">
                            {position.toUpperCase()}
                          </div>
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-4"></div>
                <div className="col-md-4">
                  <input
                    type="text"
                    placeholder="Search Employee"
                    onChange={(e) => {
                      // setCategory("");
                      setSearch(e.target.value);
                    }}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="table-responsive">
                <table className="datatable table table-stripped table table-hover table-center mb-0">
                  <thead>
                    <tr>
                      <th>Card</th>
                      <th>Merchant</th>
                      <th>Amount</th>
                      <th>Employee</th>
                      <th>Phone Number</th>
                      <th>Message</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart?.cards
                      ?.filter((item) => {
                        if (search.toLowerCase() !== "") {
                          return (
                            item?.employee?.fullname
                              ?.toLowerCase()
                              ?.includes(search) ||
                            item?.employee?.position
                              ?.toLowerCase()
                              .includes(category)
                          );
                        } else if (category !== "") {
                          return item?.employee?.position
                            ?.toLowerCase()
                            .includes(category);
                        } else {
                          return item;
                        }
                      })
                      .map((item, index) => (
                        <tr key={index}>
                          <td width="30%">
                            <img
                              src={item.image}
                              alt="Card"
                              width="70%"
                              style={{ borderRadius: "10px" }}
                            />
                          </td>
                          <td>{item.title}</td>
                          <td>¢ {item.amount}</td>
                          <td>{item?.employee?.fullname}</td>
                          <td>{item?.employee?.telephone}</td>
                          <td width="10%">{localStorage?.getItem('message')}</td>
                          <td>
                            <div className="d-flex">
                              {/* <div>
                                <a href="#edit_order" data-bs-toggle="modal" data-bs-target="#edit_order">
                                  <img src="/assets/images/edit1.png" alt="" width="50%" className="cur-pointer" />
                                </a>
                              </div> */}
                              <div>
                                {!cartItemDeleting ? (
                                  <img
                                    src="/assets/images/trash.png"
                                    alt=""
                                    width="50%"
                                    className="ml-5 cur-pointer"
                                    onClick={(e) => {
                                      removeItem(item.item_id);
                                    }}
                                  />
                                ) : (
                                  <small className="text-danger">
                                    deleting
                                  </small>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5 mb-5">
        <div className="col-md-4">
          <div>
            <h4>Merchant Info</h4>
          </div>
          <div className="mt-3">
            <h3 className="text-faint text-left text-faint-height">
              {card?.description}
            </h3>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex justify-content-between mx-5">
            <div className="ms-5">
              <div>
                <h4>Location</h4>
              </div>
              <div className="mt-3">
                <h3 className="text-faint text-left text-faint-height">
                  {card?.client?.location}
                </h3>
              </div>
            </div>
            <div className="me-5">
              <div>
                <h4>Contact Info</h4>
              </div>
              <div className="mt-3">
                <h3 className="text-faint text-left text-faint-height">
                  {card?.client_telephone}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditOrder />
    </div>
  );
};

export default OrderPreviewWithEmployees;
