import React from "react";
import { Link } from "react-router-dom";
import VerificationForm from "./VerificationForm";

const VerificationScreen = ({
  changeScreen,
  userInput,
  // setUserInput,
  // handleSubmit,
  // registrationFailed,
  // nextScreen,
  setNextScreen
}) => {
  return (
    <div className="col-lg-6 col-12 pr-0 right-div">
      <div className="card card-size1 mt-5">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between">
                <button onClick={() => setNextScreen(2)}>
                  <img src="/assets/images/arrow-left.png" alt="" />
                </button>
                <div className="d-flex float-end log-size">
                  <h4>
                    Already registered?
                    <Link to="/login" className="btn btn-outline-dark">
                      Log In
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
            <VerificationForm
              changeScreen={changeScreen}
              userInput={userInput}
              // setUserInput={setUserInput}
              // handleSubmit={handleSubmit}
              // registrationFailed={registrationFailed}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerificationScreen;
