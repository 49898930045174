import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import React from "react";

const SlickCarousel2 = () => {
  const settings = {
    infinite: true,
    autoplay: true,
    variableWidth: true,
  };

  return (
    <div className="mt-3 d-none d-sm-none d-md-block d-lg-block">
      <div className="col-md-12">
        <section className="slider variable">
          <Slider {...settings}>
            <div>
              <img
                src="/assets/images/shopImage1.png"
                width="100%"
                alt=""
                className="sli-img"
              />
            </div>

            <div>
              <img
                src="/assets/images/ads/ad-5.jpeg"
                width="100%"
                alt=""
                className="sli-img"
              />
            </div>

            <div>
              <img
                src="/assets/images/ads/new1.jpeg"
                width="100%"
                alt=""
                className="sli-img"
              />
            </div>
            <div>
              <img
                src="/assets/images/ads/new2.jpeg"
                width="100%"
                alt=""
                className="sli-img"
              />
            </div>
            <div>
              <img
                src="/assets/images/ads/new3.jpeg"
                width="100%"
                alt=""
                className="sli-img"
              />
            </div>
            <div>
              <img
                src="/assets/images/ads/ad-7.jpeg"
                width="100%"
                alt=""
                className="sli-img"
              />
            </div>
          </Slider>
        </section>
      </div>
    </div>
  );
};

export default SlickCarousel2;
