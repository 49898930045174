import GiftCard from "./GiftCard";
import { Skeleton } from "@mui/material";
import React from "react";

const FilteredCards = ({ cards, searchTerm }) => {
  return (
    <>
      {searchTerm !== "" ? (
        <>
          <div className="row">
            <div className="shop-text-header mt-4">
              <h2>Filtered Cards</h2>
            </div>
          </div>
            {/* {console.log(cards)} */}
          <div className="row">
            {cards.length > 0
              ? cards.filter(value=>{
                if(value.client_name.toLowerCase().includes(searchTerm.toLowerCase())){
                    return value
                }
              }).map((item, index) => {
                  return (
                    <div
                      className="col col-sm-6 col-md-2 col-lg-2  mt-2"
                      key={index}
                    >
                      <GiftCard
                        id={item.client_id}
                        image={item.image}
                        title={item.client_name}
                        client_location={item.client_location}
                        amount={`${item.client.min_card_price} - ${item.client.max_card_price}`}
                      />
                    </div>
                  );
                })
              : Array(6)
                  .fill("")
                  .map((_, index) => (
                    <div
                      className="col col-sm-6 col-md-2 col-lg-2  mt-2"
                      key={index}
                    >
                      <Skeleton
                        variant="rectangular"
                        width={180}
                        height={150}
                      />
                    </div>
                  ))}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default FilteredCards;
