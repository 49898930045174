import Swal from "sweetalert2";
import BulkUpload from "../../dashboard/components/BulkUpload";
import AddEmployee from "./AddEmployee";
import { useState } from "react";
import axios from "axios";
import useGetFetch from "../../common/customHooks/useGetFetch";
import Pagination from "../../common/helpers/Pagination";

const EmployeeTable = ({
  employees,
  setEmployees,
  reloadTable,
  setReloadTable,
  isloading,
  handlePageClick,
  pageCount
}) => {
  // useEffect(() => {
  //   axios
  //     .get(
  //       `/prime.sika/v1/plus/employees/${localStorage.getItem(
  //         "organization_code"
  //       )}/list`
  //     )
  //     .then((res) => {
  //       if (res.data.status === 200 && res.data.success === true) {
  //         setEmployees(res.data.data.employees);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err.response);
  //     });
  // }, []);
  const {data:positions}=useGetFetch('/prime.sika/v1/plus/employees/categories');

  const changeEmployeeState = (id, state) => {
    Swal.fire({
      title: "Confirmation",
      text: "Do you want to suspend this employee?",
      icon: "question",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#00b17d",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(
            `/prime.sika/v1/plus/employees/${localStorage.getItem(
              "organization_code"
            )}/state`,
            {
              employee_id: id,
              state: state,
            }
          )
          .then((res) => {
            setReloadTable(!reloadTable);
            Swal.fire({
              title: "sucess",
              text: res.data.data.message,
              confirmButtonColor: "#00b17d",
              icon: "success",
            });
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const deleteEmployee = (e, id) => {
    Swal.fire({
      title: "Confirmation",
      text: "Are you sure to delete this employee?",
      icon: "warning",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#00b17d",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        e.target.parentElement.innerText = "Deleting";

        axios
          .delete(
            `/prime.sika/v1/plus/employees/${localStorage.getItem(
              "organization_code"
            )}/remove`,
            {
              data: { employee_id: id },
            }
          )
          .then((res) => {
            if (res.data.success === true && res.data.status === 200) {
              setEmployees(employees.filter((employee) => employee.id !== id));
              setReloadTable(!reloadTable);
              Swal.fire({
                title: "sucess",
                text: "Employee has been deleted successfully",
                confirmButtonColor: "#00b17d",
                icon: "success",
              });
              window.location.reload();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("");

  reloadTable && setReloadTable(!reloadTable);

  return (
    <>
      <div className="col-lg-12">
        <div className="card card-table">
          <div className="card-header">
            <div className="d-flex flex-row justify-content-between">
              <div className="p-2">
                <h3 className="my-auto contact-text">Manage Employees</h3>
                <h4 className="shop-text-desc2 float-start">
                  {employees.length} Employees
                </h4>
              </div>
              <div className="p-2">
                <div className="d-flex">
                  <div className="p-2">
                    <button
                      type="button"
                      className="btn btn-dark"
                      data-bs-toggle="modal"
                      data-bs-target="#add_employee_modal"
                    >
                      + Add New
                    </button>
                  </div>

                  <div className="p-2">
                    <button
                      className="btn btn-outline-green"
                      data-bs-toggle="modal"
                      data-bs-target="#bulk_modal"
                    >
                      <span className="btn-label">
                        <i className="fa fa-upload"></i>
                      </span>{" "}
                      Bulk Upload &nbsp;
                    </button>
                  </div>
                </div>
                {/* <button className="btn btn-success">Shop GiftCard</button> */}
              </div>
            </div>
          </div>
          <div className="card-table">
            <div className="card-body booking_card">
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-row justify-content-left">
                <div className="p-1">
                        <div className="form-group">
                          <select
                            className="form-control w-100"
                            onChange={(e) => {
                              setSearch("");
                              setCategory(e.target.value);
                            }}
                          >
                            <option value="">
                              --- Filter By Positions ---
                            </option>
                            {positions?.categories?.map((position, index) => (
                              <option
                                key={index}
                                value={position}
                                className="text-capitalize"
                              >
                                <div className="text-capitalize">{position.toUpperCase()}</div>
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                </div>
                <div className="p-1 me-2">
                  <input
                    type="text"
                    placeholder="Search Employee"
                    onChange={(e) => {
                      setCategory("");
                      setSearch(e.target.value);
                    }}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="table-responsive">
                <table className="datatable table table-stripped table table-hover table-center mb-0">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Telephone</th>
                      <th>Position</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isloading ? (
                      <tr>
                        <td colSpan={6} className="text-center">
                          <h3>Loading... Please Wait...</h3>
                        </td>
                      </tr>
                    ) : (
                      employees
                        .filter((item) => {
                          if (search.toLowerCase() !== "") {
                            return (
                              item?.fullname?.toLowerCase()?.includes(search) ||
                              item?.telephone?.includes(search)
                            );
                          } else if (category !== "") {
                            return item?.position
                              ?.toLowerCase()
                              .includes(category);
                          } else {
                            return item;
                          }
                        })
                        .map((employee, index) => (
                          <tr key={index}>
                            <td>{employee?.fullname ?? "N/A"}</td>
                            <td>{employee?.email ?? "N/A"}</td>
                            <td>{employee?.telephone}</td>
                            <td className="text-capitalize">
                              {employee?.position}
                            </td>
                            {employee?.state === "is_activated" ? (
                              <td className="text-success">
                                <small>Active</small>
                              </td>
                            ) : (
                              <td className="text-danger">
                                <small>Suspended</small>
                              </td>
                            )}
                            <td>
                              <div className="d-flex">
                                {/* <div>
                                <button
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit_employee_modal"
                                  onClick={() => editEmployee(employee.id)}
                                  data-bs-placement="top"
                                  title="Edit Employee"
                                >
                                  <img
                                    src="/assets/images/edit1.png"
                                    alt=""
                                    width="40%"
                                    className="mt-2"
                                  />
                                </button>
                              </div> */}

                                <div className="p-1">
                                  <button
                                    type="button"
                                    onClick={(e) =>
                                      deleteEmployee(e, employee.id)
                                    }
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Delete Employee"
                                  >
                                    <img
                                      src="/assets/images/trash.png"
                                      alt=""
                                      width="60%"
                                    />
                                  </button>
                                </div>

                                {employee?.state === "is_activated" ? (
                                  <div className="p-1">
                                    <button
                                      className="btn btn-sm btn-outline-danger marg-left1"
                                      onClick={() =>
                                        changeEmployeeState(
                                          employee.id,
                                          "is_suspended"
                                        )
                                      }
                                    >
                                      Suspend
                                    </button>
                                  </div>
                                ) : (
                                  <div className="p-1">
                                    <button
                                      className="btn btn-sm btn-outline-success marg-left1"
                                      onClick={() =>
                                        changeEmployeeState(
                                          employee.id,
                                          "is_activated"
                                        )
                                      }
                                    >
                                      Activate
                                    </button>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))
                    )}
                  </tbody>
                </table>
                {/* <TableComponent/> */}
              </div>
            </div>
          </div>
        </div>
                <Pagination pageCount={pageCount} handlePageClick={handlePageClick}/>
      </div>

      {/* Edit Employee Modal */}
      {/* <EditEmployee employee={employee} reloadTable/> */}
      <AddEmployee reloadTable={reloadTable} setReloadTable={setReloadTable} />
      <BulkUpload reloadTable={reloadTable} setReloadTable={setReloadTable} />
    </>
  );
};

export default EmployeeTable;
