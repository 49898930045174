import axios from "axios";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";

const PageHeader = ({ user }) => {
  const navigate = useNavigate();

  const logout = () => {
    axios.post("/prime.sika/v1/users/logout", { user_type: "merchant" });
    localStorage.removeItem("company_code");
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <>
      <div className="page-header">
        <div className="row">
          <div className="col-lg-5">
            <h6 className="text-secondary text-margin-top text-fontx mt-3">
              Welcome,
            </h6>
            <h3 className="text-fontx-1 company-name-margin">
              {user?.user_details?.person?.full_name}
            </h3>
          </div>
          <div className="col-lg-4">
            <h6 className="text-secondary text-margin-top text-fontx mt-3">
              Organization
            </h6>
            <h3 className="text-fontx-1 company-name-margin text-capitalize">
              {localStorage.getItem("organization")}
            </h3>
          </div>
          <div className="col-lg-3 top-content-mobile">
            <div className="col-lg-8 mt-3">
              <Dropdown>
                <Dropdown.Toggle
                  className="d-flex admin_content form-control"
                  id="dropdown-basic"
                >
                  <div className="image">
                    <img
                      src={
                        user?.user_details?.person?.profile_pic_url ??
                        "/assets/images/team-2.png"
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="admin_name">
                    <h4
                      className="my-auto mx-1"
                      data-bs-toggle="tooltip"
                      title={user?.user_details?.person?.full_name}
                    >
                      {user?.user_details?.person?.full_name}
                    </h4>
                  </div>
                  <div className="admin_dropdownicon">
                    <img src="/assets/img/arrow-down.png" alt="" />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Link to="/dashboard/settings" className="link-color">
                      Settings
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item>
                    <button onClick={logout} className="logout-btn">
                      Logout
                    </button>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PageHeader;
