import React, { useState } from "react";
import LoadingButton from "../../common/LoadingButton";
import axios from "axios";
import Swal from "sweetalert2";
import useGetFetch from "../../common/customHooks/useGetFetch";

const Schedule = ({
  setScreen,
  userInput,
  handleUserInput,
  reload,
  toggleReload,
  cardId,
}) => {
  const [useDob, setUseDob] = useState(false);
  const [processing, setProcessing] = useState("false");
  const date = new Date();
  const {data:occassion}=useGetFetch('/prime.sika/v1/plus/cards/categories?is_occasion=true');

  const assignGiftCard = (e) => {
    e.preventDefault();
    setProcessing("true");

    var scheduled_date =
      userInput.scheduled_date === ""
        ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        : userInput.scheduled_date;

    axios
      .post("/prime.sika/v1/plus/accounts/business_customer/add-cart", {
        items: [
          {
            account_id: cardId,
            employee_id: Number(userInput.employee_id),
          },
        ],
        use_birthday_schedule: useDob,
        scheduled_date: scheduled_date,
        message: userInput.message,
      })
      .then((res) => {
        toggleReload(!reload);
        setProcessing("done");
        Swal.fire({
          title: "Success",
          text: "Card has been  assigned to employee",
          icon: "success",
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "Okay",
          confirmButtonColor: "#00b17d",
          denyButtonText: `No`,
          timer: 2000,
        });
      })
      .catch((err) => {
        console.log(err.response);
        setProcessing("false");
        Swal.fire({
          title: "Failed",
          text: "Error Assigning Card to Employee",
          icon: "warning",
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "Okay",
          confirmButtonColor: "#00b17d",
        });
      });

    // let cardsLeft = loadedCards.filter((card) => card?.account_id !== cardId);
    // setLoadedCards(cardsLeft);
    // setCardId("");
  };

  return (
    <>
      {processing !== "done" && (
        <>
          <div className="d-flex justify-content-between">
            <img
              style={{ cursor: "pointer" }}
              src="/assets/images/arrow-left.png"
              className="back button"
              onClick={() => setScreen("employee_list")}
            />

            <div className="e-wallet-text mt-2">
              <h3>Schedule Card Delivery Date and Custom Message</h3>
            </div>
          </div>
          <hr />
          <form onSubmit={assignGiftCard}>
            <div className="form-group">
              <label htmlFor="occassion">Select Occassion</label>
              <select
                value={userInput?.occassion}
                onChange={handleUserInput}
                required
                className="form-control"
              >
                {
                  occassion?.categories?.map(category=><option key={category?.id} className="text-capitalize" value={category?.id}>{category?.name}</option>)
                }
              </select>
            </div>
            <div className="check-text-size mt-3">
              <div className="form-group">
                <input
                  type="checkbox"
                  className="checkbox-design"
                  name="use_dob"
                  id="use_dob"
                  checked={useDob}
                  onChange={(e) => {
                    setUseDob(!useDob);
                  }}
                />
                <label htmlFor="use_dob">
                  <h4 className="ms-1">Schedule for employee's birthday</h4>
                </label>
              </div>
            </div>

            {!useDob && (
              <div className="form-group">
                <label htmlFor="scheduled_date">Schedule Delivery Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="scheduled_date"
                  value={userInput.scheduled_date}
                  onChange={handleUserInput}
                  required
                />
              </div>
            )}

            <div className="col-lg12 mt-3">
              <label htmlFor="message">
                <h4 className="mes-font">Custom Message (optional)</h4>
              </label>
              <textarea
                name="message"
                value={userInput.message}
                onChange={handleUserInput}
                id="message"
                className="form-control textarea-sz"
              ></textarea>
            </div>
            <div className="col-lg12 mt-3">
              {processing === "false" ? (
                <button
                  type="submit"
                  className="btn btn-outline-success form-control"
                >
                  Done
                </button>
              ) : (
                <LoadingButton />
              )}
            </div>
          </form>
        </>
      )}
      {processing === "done" && (
        <>
          <div className="d-flex justify-content-center">
            <div className="e-wallet-text mt-2">
              <h3>Card Assignment Successful</h3>
            </div>
          </div>
          <hr />
          <div className="row mt-3">
            <h4 className="px-5 text-center">
              Click on the send button from the E-gift Wallet Screen to deliver
              card to Employee
            </h4>
          </div>

          <div className="col-lg12 mt-3">
            <button
              type="submit"
              className="btn btn-outline-success form-control"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Done
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default Schedule;
