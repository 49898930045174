import React, { useState, useEffect } from "react";
import TableRow from "./trackGiftCard/TableRow";
import axios from "axios";
import Swal from "sweetalert2";
import Pagination from "../common/helpers/Pagination";

const TrackGiftCard = () => {
  const [activeIndex, setActiveIndex] = useState("");
  const [assignedCards, setAssignedCards] = useState([]);
  const [isloaded, setLoaded] = useState(false);
  const [searchParam,setSearchParam]=useState();
  const [responseCount,setResponseCount]=useState();
  const [page,setPage]=useState(1);

  const handlePageClick=(e)=>{
    setPage(e.selected+1);
  }

  useEffect(() => {
    axios
      .get(
        `/prime.sika/v1/plus/accounts/customer/bought-cards/list?link_state[]=LINKED&page=${page}`
      )
      .then((res) => {
        if (res.data.success === true && res.data.status === 200) {
          setLoaded(true);
          setAssignedCards(res.data.data.card_accounts);
          setResponseCount(res.data.data.total_pages)
        }
      })
      .catch((err) => {
        setLoaded(true);
        Swal.fire("Failed", "Error loading data", "error");
        console.log(err.response);
      });
  }, [page]);

  const filteredCards = assignedCards?.filter(card => {
    if (!searchParam?.trim()) {
      return card;
    }
    const searchTerm = searchParam.toLowerCase();
    return searchCard(card, searchTerm);
  });
  
  function searchCard(card, searchTerm) {
    const fullName = card?.employee?.fullname?.toLowerCase();
    const telephone = card?.employee?.telephone?.toLowerCase();
    const cardAmount = card?.card?.amount?.toString().toLowerCase();
    return fullName?.includes(searchTerm) || 
           telephone?.includes(searchTerm) || 
           cardAmount?.includes(searchTerm)||
           Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }).format(Date.parse(card?.purchased_at))?.includes(searchTerm)
  }
  

  return (
    <div className="col-lg-12">
      <div className="card card-table card-table-height1">
        <div className="card-header card-header-margin d-flex justify-content-between">
          <h3 className="my-auto">Track Assigned Cards</h3>
          <div>
            <input
              type="text"
              placeholder="Search Card"
              className="form-control"
              onChange={(e)=>setSearchParam(e.target.value.toLowerCase().trim())}
            />
          </div>
        </div>
        <div className="card-body booking_card">
          <div className="table-cover">
            <div className="d-flex table-content justify-content-between">
              <div className="div-size10"></div>
              <div className="div-size text-center">Gift Card</div>
              <div className="div-size">Customer Name</div>
              <div className="div-size text-center">Telephone</div>
              <div className="div-size text-center">Amount</div>
              <div className="div-size d-flex justify-content-end">
                Date Purchased
              </div>
              <div className="div-size d-flex ms-3">
                DOB
              </div>
              <div className="div-size d-flex justify-content-center">
                Action
              </div>
            </div>
          </div>

          <TableRow
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            assignedCards={filteredCards}
            isloaded={isloaded}
          />
        </div>
      </div>
          <Pagination pageCount={responseCount} handlePageClick={handlePageClick}/>
    </div>
  );
};

export default TrackGiftCard;
